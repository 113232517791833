import WS from './WS'
import queryString from 'query-string'

class WSPosition {

    getSiteValues(department, class1, config = {}) {
        return WS._get(`/selectsvalues/site?department=${department}&class1=${class1}`, config);
    };

    getSubsystemsValues(parent, class1, class2, config = {}) {
        return WS._get(`/selectsvalues/subsystem?parent=${parent}&class1=${class1}&class2=${class2}`, config);
    };

    getEqClassValues(subsystemValue, class1, class2, class3, config = {}) {
        return WS._get(`/selectsvalues/eqclassvalues?subsystem=${subsystemValue}&class1=${class1}&class2=${class2}&class3=${class3}`, config);
    };

    getRootparent(equipmentcode, config = {}) {
        return WS._get(`/selectsvalues/rootparent?equipmentcode=${equipmentcode}`, config);
    };

    autocompleteEquipment(hint, department, subsystem, eqclass, config = {}) {
        const queryParams = {
            hint,
            department,
            subsystem,
            eqclass
        };
        const query = queryString.stringify(queryParams, { skipNull: true });
        return WS._get(`/selectsvalues/autocompleteequipment?${query}`, config);
    }

    autocompleteGmaoCode(hint, department, subsystem, config = {}) {
        hint = encodeURIComponent(hint);
        return WS._get(`/selectsvalues/autocompletegmaocode?hint=${hint}&department=${department}&subsystem=${subsystem}`, config);
    }
}

export default new WSPosition();