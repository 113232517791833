import { connect } from 'react-redux';
import { showSuccess, showError, showWarning } from 'react-eis-components/actions/uiActions';
import AdminPage from "./AdminPage";

const mapStateToProps = (state) => {
    return {
        userData: state.application.userData,
        applicationData: state.application.applicationData
    }
};

const AdminPageContainer = connect(
    mapStateToProps, {
        showSuccess,
        showError,
        showWarning
    }
)(AdminPage);

export default AdminPageContainer;