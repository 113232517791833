import { SET_LOGBOOK, SET_SKIPTRANSLATIONS, UPDATE_APPLICATION } from '../actions/applicationActions';

export default function application(state = buildApplicationObject(), action) {
    switch (action.type) {
        case UPDATE_APPLICATION:
            return {
                ...state,
                ...action.value
            };
        case SET_LOGBOOK: {
            return {
                ...state,
                logbookCode: action.value
            }
        }
        case SET_SKIPTRANSLATIONS: {
            return {
                ...state,
                skipTranslations: action.value
            }
        }
        default:
            return state
    }
}

function buildApplicationObject() {
    return {}
}
