import WS from './WS'

class WSLinks {

    getLinks(caseCode, config = {}) {
        return WS._get(`/links/${caseCode}`, config);
    }

    addLink = link => {
        return WS._post(`/links/`, link);
    }

    deleteLink = linkPK => {
        return WS._delete(`/links/${linkPK}`);
    }

    updateLink = link => {
        return WS._put(`/links`, link);
    }
}

export default new WSLinks();