import React from 'react';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import * as MuiIcons from '@mui/icons-material'
import { generatePath } from 'react-router';
import { ROUTES } from 'Routes';

const LogbookLinks = ({ logbookConf, history }) => {

    return (
        <React.Fragment>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => history.push(generatePath(ROUTES.LOGBOOK_MAIN, { logbookCode: logbookConf.logbook }))}>
                        <ListItemIcon>
                            <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="All Records" />
                    </ListItemButton>
                </ListItem>

                {logbookConf.cfg.links && Object.entries(logbookConf.cfg.links).map(([key, value]) => (
                    <React.Fragment>
                        <Divider />
                        {value.map(link => <LinkListItem link={link} history={history} />)}
                    </React.Fragment>
                ))}

            </List>
        </React.Fragment>
    )
}

const LinkListItem = ({ link, history }) => {
    return (
        <ListItem disablePadding>
            <ListItemButton onClick={() => link.link?.startsWith('http') ? window.open(link.link, link.target ?? '_self') : history.push(link.link)}>
                {link.icon && <ListItemIcon><CustomIcon icon={link.icon}/></ListItemIcon> }
                <ListItemText primary={link.name} />
            </ListItemButton>
        </ListItem>
    )
}

export const CustomIcon = ({ icon }) => {
    const Icon = MuiIcons[icon]

    return Icon ? <Icon /> : null;
}

export default LogbookLinks;