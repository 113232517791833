import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FOLLOWUPWO_WO_MAP } from 'enums/LogDTO';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignDefaults, getNewObject } from './LogPageV2';
import { EAMInputField } from './ScreenEAMBlock';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 400,
    },
    blockSection: {
        marginTop: '4px',
        // padding: '4px',
        // border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.main[100]
    }
}));

const CreateWORDialog = ({ match, open, cancel, logbookCode, save, baseWo }) => {
    const dispatch = useDispatch();
    const { logbooks, userData } = useSelector(state => state.application);
    const classes = useStyles();

    const logbookConf = logbooks.find(conf => conf.logbook === logbookCode);
    const [wo, setWo] = useState({...assignDefaults({ screenLayout: logbookConf?.woScreenFields, mapDto: FOLLOWUPWO_WO_MAP}), ...baseWo});

    const extraParams = {
        "param.group": userData.eamAccount?.userGroup,
        "param.rjobtype": '*',
    }

    return <Dialog
                open={open}
                classes={{paper: classes.dialogPaper}}
            >
            <DialogTitle>
                Create Work Order/Request
            </DialogTitle>
            <DialogContent>
                {Object.entries(logbookConf?.woScreenFields)
                    .map(([block, elementList]) =>
                        logbookConf?.woScreenBlocks?.[block].attribute === 'H' ?
                        null
                        : <div key={block} className={classes.blockSection}>
                            {elementList.map(elementInfo =>
                                <EAMInputField {...{
                                    key: elementInfo.elementId,
                                    departments: userData?.eamAccount?.departmentalSecurity,
                                    rentity: 'EVNT',
                                    mapDto: FOLLOWUPWO_WO_MAP,
                                    elementInfo,
                                    keyCode: elementInfo.elementId,
                                    updateF: (obj, path) => setWo((updatedLog) => getNewObject(updatedLog, obj, path)),
                                    obj: wo,
                                    extraParams,
                                    master: 'WSJOBS',
                                    customValues: logbookConf?.logbookValues,
                                }}
                                />
                            )}
                        </div>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} variant="contained" color="info" >
                    Cancel
                </Button>
                <Button onClick={() => save(wo)} variant="contained" color="primary" >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
}

export default CreateWORDialog;
