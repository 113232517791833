import React from 'react';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LogGrid from '../LogMain';

const ListPanel = ({ data, logbookCode, history }) => {
    return (
        <Grid xs={data.dimensions?.xs ?? 12} md={data.dimensions?.md ?? 6} >
            <Paper elevation={0} sx={{ margin: 1 }}>
                <Card variant="outlined" >
                    <CardHeader title={data.title}/>

                   <div style={{ padding: 0, height: data.dimensions?.height ?? 400, width: '100%' }}>
                        <LogGrid
                            hideFooter
                            hideHeader
                            gridName={data.gridName}
                            dataspyId={data.dataspyId}
                            logbookDashboard={logbookCode}
                            filters={data.filters}
                            history={history}
                            customLink={data?.customLink}
                        />
                    </div>

                </Card>
            </Paper>
        </Grid>
    )
}

export default ListPanel;