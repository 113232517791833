import { Button, Typography } from "@mui/material";
import WSCache from "tools/rest/WSCache";

const AdminPage = (props) => {
    const { userData, showSuccess, showError } = props;
    const { udfchkbox01, udfchkbox02 } = userData.eamAccount?.userDefinedFields ?? {};

    if (![udfchkbox01, udfchkbox02].includes('true')) {
        return null;
    }

    const handleClearCache = () => {
        WSCache.delete().then(() => {
            showSuccess("Clear cache success.")
        }).catch(() => {
            showError("Clear cache failed.")
        })
    }

    return (
        <section
            style={{
                padding: "1rem",
                alignContent: "center",
                height: "100%"
            }}>
                <div style={{ padding: "1rem 0" }}>
                    <Typography variant="h4">Admin page</Typography>
                </div>
                <Button
                    onClick={handleClearCache}
                    variant="contained"
                >
                    Clear Cache
                </Button>
        </section>
    )
}

export default AdminPage
