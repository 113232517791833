import { HelpOutline } from '@mui/icons-material';
import { Icon, TableCell, Typography, alpha } from '@mui/material';
import { withStyles } from '@mui/styles';
import EAMGrid from "eam-components/dist/ui/components/grids/eam/EAMGrid";
import { EAMGridContextProvider } from "eam-components/dist/ui/components/grids/eam/EAMGridContext";
import React from 'react';
import history from "tools/history";
import WS from 'tools/rest/WSCase';
import InfoPage from "ui/components/infopage/InfoPage";
import SyncedQueryParamsEAMGridContext from 'ui/components/SyncedQueryParamsEAMGridContext';
import { COLORS } from 'tools/COLORS';

export const SUBTYPE = 'csm_udfchar23';
export const SOURCE = 'csm_udfchar26';
export const NATURE = 'csm_udfchar27';
export const CRYO_LOSS = 'csm_udfchkbox05';
export const CRYO_LOSS_TRUE = '+';

export const NATURES = {
    FAULT: 'Fault',
    INSTRUCTIONS: 'Instructions',
    MOD_REQUEST: 'Modification request',
    INFORMATION: 'Information',
    ONGOING_OP: 'Ongoing operation',
    LOCK_OUT: 'Lock Out',
    INTERVENTION_AUTHORIZATION: 'Intervention Authorisation',
    CRYO_OK_MAINTAIN_LOSS: 'Cryo OK/Maintain Loss'
};

export const SOURCES = {
    BEEP: 'Beep'
}

export const SUBTYPES = {
    BALANCE_HELIUM: 'Balance Helium',
    CCC_CONSIGNES: 'ccc consignes'
}

const styles = (theme) => ({
    rowStyler: {
        cursor: "pointer",
        minHeight: "44px",
        maxHeight: '132px',
        '&:hover': {
            boxShadow: `inset 0px 0px 0px 3px ${theme.palette.primary.main}, inset 0 0 0 999px ${alpha(theme.palette.primary.main, 0.15)}`
        }
    },
    icon: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

export const ICONS = {
    BELL: 'notifications',
    BELL_RINGING: 'notifications_active',
    INSTRUCTIONS: 'build',
    ONGOING_OP: 'storage',
    BALANCE_HELIUM: 'swap_horiz',
    MOD_REQUEST: 'settings'
}

class LogSelection extends React.Component {

    state = {
        orderedStyles: [],
    }

    componentDidMount() {
        this.setState({orderedStyles: this.getStylesFunction(this.props.styles)});
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.styles) !== JSON.stringify(this.props.styles)) {
            this.setState({orderedStyles: this.getStylesFunction(this.props.styles)});
        }
    }

    defaultSort() {
        const { extraVariables, logbookCode } = this.props;
        const sortColumn = extraVariables[logbookCode] && extraVariables[logbookCode]['SORTCOLUMN'];
        return sortColumn ? [{
            sortBy: sortColumn,
            sortType: 'DESC'
        }] : [];
    }

    getStylesFunction = (styles) => {
        const stylesByAttr = styles?.reduce((acc, el) => ({...acc, [el.attributeName]: [...(acc[el.attributeName] || []), el]}), {}) || {}
        const orderedStyles = Object.values(stylesByAttr)
            .map(
                styl => Object.values(
                    styl?.reduce((acc, el) => ({...acc, [el.sequence]: [...(acc[el.sequence] || []), el]}), {}) || {}
                )
                .sort((a, b) => a[0].sequence - b[0].sequence)
            );
        return orderedStyles;
    }

    applyStylesFunction = (values) => {
        const { orderedStyles } = this.state;
        const styleApply = orderedStyles
            .map(style =>
                style.find(conditionList =>
                    conditionList
                        .every(cond =>
                            ((val) => cond?.operator === 'EQUALS' ? val === cond.tagValue : cond?.operator === 'BEGINS' ? val?.startsWith(cond.tagValue) : false)(values[cond.tagName?.toLowerCase()])
                        )
                )?.[0]
            )
            .filter(s => s)
            .reduce((acc, el) => ({...acc, [el.attributeName]: el}) ,{});

        return styleApply;
    }

    rowStyler = ({ values }) => {
        const { classes } = this.props;
        const styleApply = this.applyStylesFunction(values);
        return {
            style: COLORS[styleApply?.color?.attributeValue],
            className: classes.rowStyler,
            onClick: () => this.onRowClick(values)
        }
    };

    handleChangeDataspy = (dataspy) => {
        history.push({
            search: `?dataspy=${dataspy}`
        });
    };

    cellRenderer = ({ column, value, row }) => {
        const { logbookCode, applicationData, styles, classes } = this.props;
        const caseCode = row.values["casecode"];

        if (column.id === 'openInNewTab') {
            return <Icon
                variant="contained"
                color="primary"
                style={{
                    textAlign: 'center',
                }}
                onClick={
                    evt => {
                        window.open("log/" + caseCode,  "_blank")
                        evt.stopPropagation();
                    }
                }
            >
                open_in_new
            </Icon>
        }

        if (column.id === 'icon') {
            const styleApply = this.applyStylesFunction(row.values);
            return <div className={classes.icon}><Icon>{styleApply?.icon?.attributeValue}</Icon></div>
        }

        const isExtraColumn = ['commentCount', 'woCount', 'wos'].includes(column.id);

        if (isExtraColumn && !this.state.summaries[caseCode]) {
            return <HelpOutline
                onClick={(event) => this.fetchCaseSummary(row.values, event)}
            />
        }

        if (isExtraColumn && this.state.summaries[caseCode]) {
            const data = this.state.summaries[caseCode];

            if (data.isFetching) {
                return "..."
            }
            else {
                if (column.id.endsWith("Count")) {
                    return <Typography>
                        {this.state.summaries[caseCode][column.id]}
                    </Typography>
                } else {
                    return <Typography
                        style={{
                            color: "inherit"
                        }}
                        onClick={this.stopPropagation}>
                        {this.state.summaries[caseCode].wos.map(wo =>
                            <>
                                <a target="_blank" rel='noreferrer noopener' href={`${applicationData.eamlightUrl}workorder/${wo}`}>{wo}</a>
                                <br />
                            </>
                        )}
                    </Typography>
                }
            }
        }
    };

    fetchCaseSummary = (row, event) => {
        this.stopPropagation(event);

        const caseCode = row.casecode;

        this.setState((prevState) => ({
            ...prevState,
            summaries: {
                ...prevState.summaries,
                [caseCode]: {
                    isFetching: true
                }
            }
        }));

        WS.getCaseSummary(caseCode)
            .then(response => {
                this.setState((prevState) => ({
                    ...prevState,
                    summaries: {
                        ...prevState.summaries,
                        [caseCode]: response.body.data
                    }
                }));
            }).catch(error => {
                this.props.handleError(error);
                this.setState((prevState) => ({
                    ...prevState,
                    summaries: {
                        ...prevState.summaries,
                        [caseCode]: {
                            isFetching: false
                        }
                    }
                }));
        })
    };

    stopPropagation = e => {
        e.cancelBubble = true;
        if (e.stopPropagation) {
            e.stopPropagation();
        }
    };

    onRowClick = (row) => {
        const { extraVariables, logbookCode } = this.props;
        const openInSame = extraVariables[logbookCode] && extraVariables[logbookCode]['OPENLOGSAMETAB'] === 'true';

        const caseCode = row.casecode;
        if (openInSame) {
             this.props.history.push("log/" + caseCode);
        } else {
            window.open("log/" + caseCode,  "_blank")
        }
    };

    render() {
        const { logbookData, logbookCode, classes, handleError, extraVariables } = this.props;
        const openInSame = extraVariables[logbookCode]?.['OPENLOGSAMETAB'] === 'true';

        if (!logbookData) {
            return <InfoPage title="No logbook found" message={`Requested logboook ${logbookCode} not found or you don't have access.`}/>
        }

        const extraColumns = [
            {
                width: '50px',
                label: "Icon",
                t: 'icon',
            },
        ];

        if (openInSame) {
            extraColumns.unshift({
                width: '40px',
                headerLabel: "",
                t: 'openInNewTab',
                position: "before"
            });
        }
        const customGridName = extraVariables[logbookCode]?.['GRIDNAME'] ?? logbookCode;

        return <SyncedQueryParamsEAMGridContext
                    searchOnMount
                    gridSort={this.defaultSort()}
                    gridName={customGridName}
                    modifyEAMGridColumns={(s) => [...(extraColumns?.map(({ width, t, label }) => ({ name: t, label: label ?? "", width: width?.replaceAll('px', '')})) ?? []), ...s]}
                    useNative={true}
                    cellRenderer={this.cellRenderer}
                    initialShowFilters={false}

                >
                <EAMGrid
                    getRowProps={(row) => this.rowStyler?.(row, this.props.styles)}
                    handleError={handleError}
                />
            </SyncedQueryParamsEAMGridContext>
    }
}

export default withStyles(styles)(LogSelection);