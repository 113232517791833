import WS from "./WS";

class WSFollowups {
    getFollowUps(caseCode, config = {}) {
        return WS._get(`/followups/${caseCode}`, config);
    }

    createFollowUp = (screenCode, caseCode, workOrder) => {
        return WS._post(`/followups/${screenCode}/${caseCode}`, workOrder);
    };

    createFollowUpWOR = async (caseCode, workOrder) => (await WS._post(`/v2/logs/${caseCode}/followups`, workOrder))?.body.data

    deleteFollowUp = (caseCode, woCode) => {
        return WS._delete(`/followups/${caseCode}/${woCode}`);
    };

    addFollowUp = (caseCode, woCode) => {
        return WS._post(`/followups/${caseCode}/${woCode}`);
    };

    autocompleteWO = (entity, hint, config = {}) => {
        return WS._get(
            `/followups/autocomplete/${entity.toLowerCase()}/?hint=${hint}`,
            config
        );
    };

    getProblemCodes = (equipmentCode) => {
        return WS._get(
            `/followups/problemcodes?equipmentCode=${equipmentCode}`
        );
    };

    getCostCode = (equipmentCode) => {
        return WS._get(
            `/followups/costcode?equipmentCode=${equipmentCode}`
        ).then((response) => {
            let costCode = response.body.data;
            return costCode && typeof costCode === "string"
                ? response
                : {
                      ...response,
                      body: {
                          ...response.body,
                          data: "",
                      },
                  };
        });
    };

    autocompleteEmployee = (code, config = {}) => {
        code = encodeURIComponent(code);
        return WS._get(`/autocomplete/employee/${code}`, config);
    };
}

export default new WSFollowups();
