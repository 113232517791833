import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import WS from '../../../../../tools/rest/WSFollowUps';
import { withStyles } from '@mui/styles';

const styles = {
    dialogPaper: {
        width: "90vh",
        height: "50vh",
        minHeight: 300,
        maxWidth: 400,
    }
}

class AddWODialog extends React.Component {

    componentDidMount = () => {
        this.children = {};
    };

    state = {
    };

    render() {
     const { cancel, open, updateProperty, woCodeToLink, classes } = this.props;

        return (
            <Dialog
                open={open}
                classes={{paper: classes.dialogPaper}}
            >
                <DialogTitle>
                    Link Work Order
                </DialogTitle>
                <DialogContent>
                    <EAMAutocomplete
                        children={this.children}
                        elementInfo={{
                            attribute: "O",
                            xpath: "woToLink"
                        }}
                        label={"Work Order"}
                        onChange={(val) => updateProperty("woCodeToLink", val?.code)}
                        value={woCodeToLink}
                        valueKey="woCodeToLink"
                        autocompleteHandler={(hint, config) => WS.autocompleteWO("workorder", hint, config)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel} color="primary" >
                        Cancel
                    </Button>
                    <Button onClick={this.props.save} color="primary" >
                        Add link
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(AddWODialog);