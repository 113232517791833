import React, {Component} from 'react';
import CERNToolbar from "./CERNToolbar";
import AppBar from '@mui/material/AppBar'
import SnackbarContainer from "../components/snackbar/SnackbarContainer";
import ConfirmDialogContainer from "../components/confirmdialog/ConfirmDialogContainer";
import { withStyles } from '@mui/styles';

const styles = theme => ({
    containerStyle: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        '-webkit-overflow-scrolling': "touch",
    },
    appBarStyle: {
        position: "inherit",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flex: '0 1 48px',
        maxHeight: '48px',
        minHeight: '48px',
    },
    mainContentStyle: {
        flex: 1,
        overflowY: "hidden",
        height: "100%",
        width: '100%',
        boxSizing: 'border-box',
        padding: 0,
        margin: 0,
        flexDirection: 'column',
    },
    footerStyle: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "right",
        fontSize: "14px",
        color: "white",
        padding: "5px",
        maxHeight: '27px',
        flex: '0 1 27px',
        overflowX: 'clip',
        whiteSpace: 'nowrap',
    },
});

/**
 * Generic layout of a EIS application
 */
class EISLayout extends Component {

    render() {
        const {classes} = this.props;
        let appBarStyle = {};
        if (!this.props.showCERNToolbar) {
            appBarStyle = {top: 0};
        }

        const AppName = this.props.appName;

        return (
            <div className={classes.containerStyle}>
                {this.props.showCERNToolbar && <CERNToolbar {...this.props}/>}
                <AppBar className={classes.appBarStyle} style={appBarStyle}>
                    <div><AppName {...this.props} /></div>
                    {this.props.appBarContent}
                </AppBar>

                <div className={classes.mainContentStyle}>
                    <SnackbarContainer/>
                    <ConfirmDialogContainer/>
                    {this.props.children}
                </div>

                {this.props.footerContent &&
                <div className={classes.footerStyle}>
                    {this.props.footerContent}
                </div>
                }
            </div>
        )
    }
}

EISLayout.defaultProps = {
    showCERNToolbar: true
};

export default withStyles(styles)(EISLayout);