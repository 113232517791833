import axios from "axios";
import eamComponentsClient from "eam-components/dist/tools/ajax";
import ErrorTypes from "../../enums/ErrorTypes";
import keyCloakClient from "../../tools/rest/keycloakClient";

import { keycloak, tokens, handleTokens } from "../../AuthWrapper";

const DEFAULT_TIMEOUT = 40000;

const client = axios.create({
    timeout: DEFAULT_TIMEOUT,
});

const convertResponse = (response) => {
    return {
        status: response.status,
        body: response.data,
    };
};

const convertError = (error) => {
    if (!error) throw {};

    if (axios.isCancel(error)) {
        throw {
            type: ErrorTypes.REQUEST_CANCELLED,
        };
    }

    if (error.response) {
        throw {
            type: ErrorTypes.SERVER_ERROR,
            response: {
                status: error.response.status,
                body: error.response.data,
            },
        };
    }
    if (error.code === 'ECONNABORTED') {
        throw {
            type: ErrorTypes.CONNECTION_ABORDED,
        };
    }
    // Because we are behind IT-DB proxy this will be only reached when a redirect was sent (i.e. SSO session has expired)
    // TODO: should be carefully studied
    // window.location.reload(true);
    throw {};
};

const injectBearerToken = ({ config, clientID }) => {
    const newConfig = config;
    const clientTokens = tokens[clientID];
    if (!clientTokens) return newConfig;
    const token = clientTokens.token || clientTokens.access_token;
    if (!token) return newConfig;
    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
};

const getClientID = ({ url }) => {
    if (url.startsWith(process.env.REACT_APP_BACKEND)) {
        return process.env.REACT_APP_KEYCLOAK_CLIENTID;
    } else if (url.includes("/cern-eam-services/rest")) {
        return "cern-eam-services";
    }
};

const exchangeToken = async ({ sourceClient, targetClient }) => {
    if (!tokens[sourceClient]) return null;
    // if target client token exists, return it since it should still be valid (otherwise the timeout handles the 'refresh')
    if (tokens[targetClient]) return tokens[targetClient];

    const formData = new URLSearchParams();
    formData.append("client_id", sourceClient);
    formData.append("subject_token", tokens[sourceClient].token);
    formData.append("audience", targetClient);
    formData.append(
        "grant_type",
        "urn:ietf:params:oauth:grant-type:token-exchange"
    );
    formData.append(
        "request_type",
        "urn:ietf:params:oauth:token-type:access_token"
    );

    try {
        const response = await keyCloakClient.post(
            `/protocol/openid-connect/token`,
            formData
        );

        handleTokens({ clientID: targetClient, freshTokens: response.data });
        setTimeout(
            () => exchangeToken({ sourceClient, targetClient }),
            (response.data.expires_in - 20) * 1000
        );
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

const onRequestFulfilledInterceptor = (config) => {
    if (process.env.REACT_APP_LOGIN_METHOD === 'OPENID') {
        // updateToken if it will last less than 5 minutes
        return keycloak.updateToken(300).then(async () => {
            const clientID = getClientID({ url: config.url });
            if (clientID !== process.env.REACT_APP_KEYCLOAK_CLIENTID) {
                await exchangeToken({
                    sourceClient: process.env.REACT_APP_KEYCLOAK_CLIENTID,
                    targetClient: clientID,
                });
            }
            return injectBearerToken({
                config,
                clientID,
            });
        });
    }
    return config;
}

const onRequestRejectedInterceptor = (error) => Promise.reject(error);

eamComponentsClient
    .getAxiosInstance()
    .interceptors.request.use(
        onRequestFulfilledInterceptor,
        onRequestRejectedInterceptor
    );

client.interceptors.request.use(
    onRequestFulfilledInterceptor,
    onRequestRejectedInterceptor
);

client.interceptors.response.use(convertResponse, convertError);

export default client;
