import { Add, Cached, ChevronLeft } from '@mui/icons-material';
import { Checkbox, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import { Component } from 'react';
import { generatePath } from 'react-router';
import { ROUTES } from 'Routes';
import WSCache from 'tools/rest/WSCache';
import { CustomIcon } from 'ui/pages/LogbookSelection/LogbookLinks';

const styles = (theme) => (
    {
        logbookDrawer: {
            backgroundColor: theme.palette.primary.main,
            justifyContent: 'space-between',
            color: '#FFF',
            fontWeight: "bold",
            fontSize: 18,
            display: 'flex',
            alignItems: 'center',
            height: '48px',
            boxShadow: theme.shadows[4],
        }
    }
);


const LinkDrawer = ({ history, link, classes, openCreateNewLogPage, handleDrawerClose }) =>
    <MenuItem
        key={link.name}
        onClick={() => {
            link.link?.startsWith('http') ? window.open(link.link, '_self') : history.push((link.link?.startsWith('/') ? '' : '/') + link.link);
            handleDrawerClose();
        }}
    >
        {link.icon && <ListItemIcon><CustomIcon {...link} /></ListItemIcon> }
        <ListItemText classes={{primary: classes.primary}} inset primary={link.name}/>
    </MenuItem>

const style = {
    margin: 0,
    padding: 0,
}

class LogbookDrawer extends Component {

    handleClearCache = (handleDrawerClose) => {
        const { showError, showSuccess } = this.props;
        WSCache.delete().then(() => {
            showSuccess("Clear cache success.")
        }).catch(() => {
            showError("Clear cache failed.")
        }).finally(handleDrawerClose)
        ;

    }

    openCreateNewLogPage = () => {
        const { handleDrawerClose, logbookCode, history } = this.props;
        history.push(generatePath(ROUTES.LOGBOOK_CREATELOGV2, { logbookCode }));
        handleDrawerClose();
    };

    render() {
        const { classes, drawerWidth, handleDrawerClose, open, logbookCode, history, logbooks, userData, applicationData, setSkipTranslations, skipTranslations } = this.props;

        const logbookConf = logbooks.find(conf => conf.logbook === logbookCode);

        const { udfchkbox01, udfchkbox02 } = userData.eamAccount?.userDefinedFields ?? {};

        const accessToCache = [udfchkbox01, udfchkbox02].includes('true') || applicationData?.adminUserGroup === userData?.eamAccount?.userGroup;

        return (
            <Drawer
                variant="persistent"
                open={open}
                onClick={(evt) => {evt.stopPropagation()}}
            >
                <div style={{width: drawerWidth, backgroundColor: '#fafafa', height: '100%'}}>
                    <div className={classes.logbookDrawer + " " + "cern-toolbar"}>
                        <div style={{marginLeft: 10}}>EAM Logbook</div>
                        <IconButton onClick={handleDrawerClose} style={{color: 'white'}}>
                            <ChevronLeft/>
                        </IconButton>
                    </div>
                    <div style={{padding: 5}}>
                        <EAMSelect
                            elementInfo={{}}
                            valueKey={'asd'}
                            label={'Logbook'}
                            options={logbooks?.map(({logbook, description}) => ({code: logbook, desc: description}))}
                            value={logbookConf?.logbook}
                            onChange={(val) => {
                                history.push(generatePath(ROUTES.LOGBOOK_MAIN, { logbookCode: val?.code }));
                                handleDrawerClose();
                            }}
                        />

                        {logbookCode &&
                            <>
                                <MenuList>
                                    <MenuItem
                                        onClick={this.openCreateNewLogPage}
                                        className={classes.menuItem}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <Add/>
                                        </ListItemIcon>
                                        <ListItemText classes={{primary: classes.primary}} inset primary="New Log"/>
                                    </MenuItem>
                                </MenuList>
                                <Divider/>
                            </>
                        }

                        <MenuList>
                            {Object.entries(logbookConf?.cfg?.links || {})
                                .map(([key, links]) =>
                                    [
                                        links?.map((link) =>
                                            <LinkDrawer
                                                history={history}
                                                link={link}
                                                classes={classes}
                                                openCreateNewLogPage={this.openCreateNewLogPage.bind(this)}
                                                handleDrawerClose={handleDrawerClose}
                                            />
                                            ),
                                        <Divider key={key + 'divider'}/>
                                    ]
                            )}
                        </MenuList>

                        {accessToCache ?
                         <>
                            <MenuList>
                                <MenuItem
                                    onClick={() => setSkipTranslations(!skipTranslations)}
                                >
                                    <ListItemIcon>
                                        <Checkbox style={style} checked={!!skipTranslations}/>
                                    </ListItemIcon>
                                    <ListItemText inset primary="Skip translations"/>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => this.handleClearCache(handleDrawerClose)}
                                >
                                    <ListItemIcon>
                                        <Cached/>
                                    </ListItemIcon>
                                    <ListItemText inset primary="Clear cache"/>
                                </MenuItem>
                            </MenuList>
                        </>
                        : null }
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default withStyles(styles)(LogbookDrawer);