import { showError, showSuccess, showWarning } from 'react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { updateApplication } from "./actions/applicationActions";
import Config from "./config";
import Logbook from './Logbook';
import Themes from "./themes";

const mapStateToProps = (state, ownProps) => {
    const applicationData = state.application.applicationData || {};
    const selectedTheme = Themes[Config.theme[applicationData.environment]] || Themes.DANGER;

    return {
        theme: selectedTheme,
        userData: state.application.userData,
        storeData: state.application.storeData,
        loginToken: state.application.loginToken,
        kioskMode: state.application.kioskMode,
        logbookCode: state.application.logbookCode,
        applicationData: state.application.applicationData,
        configuration: state.application.logbooks,
        logbookDesc: state.application.logbooks?.find(conf => conf.logbook === state.application.logbookCode)?.description,
    }
};

const LogbookContainer = connect(mapStateToProps, {
    updateApplication,
    showSuccess,
    showError,
    showWarning,
})(Logbook);

export default LogbookContainer