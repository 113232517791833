import { Add, ContentCopy, Delete, Email, EventNote, History, Link, MoreVert, Print, Refresh, Save } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { ROUTES } from 'Routes';
import { LOG_DTO2 } from 'enums/LogDTO';
import React from 'react';
import { generatePath } from 'react-router';
import ParametrizedURLDialog from '../dialogs/ParametrizedURLDialog';

class LogToolbar extends React.Component {

    toolbarStyle = {
        display: "flex",
        width: "100%",
        backgroundColor: "#fafafa",
        boxShadow: "0 1px 0 rgba(0, 0, 0, 0.06), 0 2px 0 rgba(0, 0, 0, 0.075), 0 1px 0 rgba(0, 0, 0, 0.05), 0 4px 0 rgba(0, 0, 0, 0.015)",
        flex: "0 1 40px",
        border: "1px solid #d1d1d1",
        borderTop: 0,
        maxHeight: '40px',
        height: '40px',
        minHeight: '40px',
        overflow: 'hidden',
    };

    toolbarContentStyle = {
        display: "flex",
        alignItems: "center",
        height: 40
    };

    verticalLineStyle = {
        height: 25,
        borderRight: "1px solid gray",
        margin: 5
    };

    iconStyle = {
        opacity: 0.8,
        marginLeft: 5,
        marginRight: 3
    };

    state = {
        width: window.innerWidth,
        isURLGenerationOpen: false
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.updateDimensions()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState(() => ({
            width: window.innerWidth
        }));
    };

    emailHandler = () => {
        const { logbookCode, logNumber } = this.props;
        // window.open("mailto:?Subject=Log Number " + this.props.logNumber + `&body=${window.location}/${this.props.logbookCode}/log/${this.props.logNumber}`, '_self');
        window.open("mailto:?Subject=Log Number " + logNumber + `&body=${window.location.origin}${generatePath(ROUTES.LOGBOOK_EDITLOGV2, {logbookCode, logNumber })}`, '_self');
    };

    printHandler = () => {
        const url = `${this.props.reportUrl}${this.props.logNumber}`;
        window.open(url, '_blank');
    };

    handleMenuItemClose() {
        if (this.state.anchorEl) {
            this.setState({anchorEl: null});
        }
    }

    handleClick(e) {
        this.setState({anchorEl: e.currentTarget});
    }

    openHistoryWindow = () => {
        const url = `${window.origin}${process.env.REACT_APP_FRONTEND}${this.props.logbookCode}/history/${this.props.logNumber}`;
        window.open(url, '_blank', "height=800, width=800");
    };

    cloneHandler = () => {
        this.props.cloneHandler();
    };

    render() {
        const isMobile = this.state.width < 1050;
        const { readonly, newEntity, logNumber, saveHandler, newHandler, url } = this.props;

        return (
            <div style={this.toolbarStyle}>
                <div style={this.toolbarContentStyle}>
                    <EventNote style={this.iconStyle}/>
                    <span style={{margin: 10}}>Log <span style={{fontWeight: 500}}>{!newEntity && logNumber}</span></span>
                    <div style={this.verticalLineStyle}/>
                    {readonly ? null : <><Button onClick={saveHandler}>
                        <Save style={this.iconStyle}/>
                        SAVE
                    </Button>
                    <Button onClick={newHandler}>
                        <Add style={this.iconStyle}/>
                        NEW
                    </Button></>}
                    {isMobile && this.renderCompactMenu()}
                    {!isMobile && this.renderNormalMenu()}
                </div>
                {this.state.isURLGenerationOpen &&
                    <ParametrizedURLDialog
                        title="Generate Parametrized URL"
                        open={this.state.isURLGenerationOpen}
                        onClose={() => this.setState({ isURLGenerationOpen: false })}
                        params={this.getQueryParamsSet()}
                        url={url}
                    />
                }
            </div>
        );
    }

    getQueryParamsSet = () => {
        const { userDefinedFields, code, customField, customFieldMap, ...otherParams } = this.props.log;
        delete otherParams[LOG_DTO2.CODE];
        delete otherParams[LOG_DTO2.CUSTOM_FIELDS];

        const allParams = { ...otherParams,  ...userDefinedFields };
        return Object.entries(allParams).reduce((a,[key, value]) => (value == null || value === "" ? a : { ...a, [key]: value }), {});
    }

    renderNormalMenu = () => (
        <React.Fragment>
            {this.props.readonly ? null :
            <Button onClick={() => {
                this.props.openConfirmDialog({
                        title: "Confirm delete",
                        message: "Are you sure you want to delete this log?",
                        waitForCompletion: true
                    }, this.props.deleteHandler)
                }} disabled={this.props.newEntity}>
                <Delete style={this.iconStyle}/>
                DELETE
            </Button>}
            <Button onClick={this.props.reload} disabled={this.props.newEntity}>
                <Refresh style={this.iconStyle}/>
                RELOAD
            </Button>
            {!this.props.newEntity && (
                <React.Fragment>
                    <div style={this.verticalLineStyle}/>
                    <Button onClick={this.emailHandler}>
                        <Email style={this.iconStyle}/>
                        EMAIL
                    </Button>
                    <Button onClick={this.printHandler}>
                        <Print style={this.iconStyle}/>
                        PRINT
                    </Button>
                    {/* <Button onClick={this.openHistoryWindow}>
                        <History style={this.iconStyle}/>
                        HISTORY
                    </Button> */}
                    {this.props.readonly ? null : <Button disabled={this.props.newEntity} onClick={this.cloneHandler} >
                        <ContentCopy/>
                        CLONE
                    </Button>}
                    <Button disabled={this.props.newEntity} onClick={this.props.auditHandler} >
                        <History style={this.iconStyle}/>
                        HISTORY
                    </Button>
                </React.Fragment>
            )}
            <Button onClick={() => this.setState({ isURLGenerationOpen: true })}>
                <Link style={this.iconStyle}/>
                Generate URL
            </Button>
        </React.Fragment>
    );

    renderCompactMenu = () => {
        const { newEntity } = this.props;
        return <React.Fragment>
            <Button
                style={{padding: 8, minWidth: "unset"}}
                aria-label="More"
                aria-owns={this.state.anchorEl ? 'long-menu' : null}
                onClick={this.handleClick.bind(this)}>
                <MoreVert/>
            </Button>
            <Menu id="long-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuItemClose.bind(this)}>
                {this.props.readonly ? null :<MenuItem onClick={() => {
                    this.props.openConfirmDialog({
                        title: "Confirm delete",
                        message: "Are you sure you want to delete this log?",
                        waitForCompletion: true
                    }, this.props.deleteHandler)
                }} disabled={this.props.newEntity}>
                <Delete style={this.iconStyle}/>
                        Delete
                </MenuItem>}
                <MenuItem disabled={this.props.newEntity} onClick={this.emailHandler}>
                    <Email style={this.iconStyle}/>
                    Email
                </MenuItem>
                <MenuItem disabled={this.props.newEntity} onClick={this.printHandler}>
                    <Print style={this.iconStyle}/>
                    Print
                </MenuItem>
                <MenuItem disabled={this.props.newEntity} onClick={this.props.auditHandler}>
                    <History style={this.iconStyle}/>
                    History
                </MenuItem>
                {this.props.readonly ? null :<MenuItem disabled={this.props.newEntity} onClick={this.cloneHandler}>
                    <ContentCopy/>
                    Clone
                </MenuItem>}
                <Button onClick={() => this.setState({ isURLGenerationOpen: true })}>
                    <Link style={this.iconStyle}/>
                    Generate URL
                </Button>
            </Menu>
        </React.Fragment>
    }
}


export default LogToolbar;