
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs-ng/EAMCheckbox';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs-ng/EAMDatePicker';
import EAMDateTimePicker from 'eam-components/dist/ui/components/inputs-ng/EAMDateTimePicker';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import { LOG_CASE_MAP, LOG_DTO2 } from 'enums/LogDTO';
import { useDispatch, useSelector } from 'react-redux';
import WSCaseV2 from 'tools/rest/WSCaseV2';
import Panel from 'ui/components/Panel';

export const isUdf = (elementInfo) => /EAMID_[^_]*_(Standard)?UserDefinedFields_.*/.test(elementInfo.xpath);

export const getLogCaseKey = (elementInfo, mapDto) => {
    return isUdf(elementInfo) ? elementInfo.elementId : mapDto[elementInfo.elementId];
}

export const CONFIGURATION_TYPE = {
    LOV: 'LOV',
    ATTRIBUTE: 'ATTRIBUTE',
    WIDTH: 'WIDTH',
}

export const compare = (operator, thisValue, otherValue) =>
    operator === 'EQUALS' ? (thisValue && thisValue === otherValue)
    : operator === 'NOT_EQUALS' ? (thisValue && thisValue !== otherValue)
    : operator === 'BEGINS' ? thisValue?.startsWith(otherValue)
    : operator === 'IS_EMPTY' ? !thisValue
    : operator === 'NOT_EMPTY' ? !!thisValue
    : ['>', 'GREATER_THAN'].includes(operator) ? thisValue > otherValue
    : ['<', 'LESS_THAN'].includes(operator) ? thisValue < otherValue
    : false
    ;

export const getValuesConf = ({ customValues, elementId, obj, mapDto }) => {
    let valuesThatApply = customValues
        ?.filter(f => !f.notused)
        .filter(s => {
            if (s.fieldcode !== elementId) {
                return false;
            }
            if (s.parentFieldCode == null) {
                return true;
            }
            if (s.parentFieldCode) {
                const parentObj = s.parentFieldType && s.parentFieldType !== '*' ?
                    obj[s.parentFieldType] : obj;

                const parentCode = mapDto[s.parentFieldCode] || s.parentFieldCode;

                return compare(s.operator, parentObj?.[parentCode], s.parentFieldValue)
            }
            return false;
        })
        .sort((a, b) => a.order - b.order)
        ;
        const valuesConf = valuesThatApply.reduce((acc, el) => ((key) => ({ ...acc, [key]: [...(acc[key] || []), el]}))(el.confType || CONFIGURATION_TYPE.LOV), {});
        return valuesConf;
}

export const EAMInputField = ({ options, customValues = [], departments, mapDto, elementInfo, values, updateF, obj, errorMessages
    , master = 'CSCASE', rentity = 'CASE', autocompleteHandler, autocompleteHandlerParams, extraParams = {}, readonly = false, skipTranslations
    , hasAccessRights = true,
}) => {
    const keyCode = getLogCaseKey(elementInfo, mapDto);
    const udf = isUdf(elementInfo);
    const value =  udf ? obj?.userDefinedFields?.[keyCode] : obj?.[keyCode];

    const valuesConf = getValuesConf({ customValues, elementId: elementInfo.elementId, obj, mapDto });

    let dropdown = options || valuesConf[CONFIGURATION_TYPE.LOV]
        ?.sort((a, b) => a.order - b.order)
        //TODO desc
        .map(field => ({code: field.value, desc: field.value}));

    let attribute = valuesConf[CONFIGURATION_TYPE.ATTRIBUTE]?.[0]?.value || elementInfo.attribute;

    if (attribute === 'H') {
        return null;
    } else if (hasAccessRights && ['status', 'workorderstatus'].includes(elementInfo.elementId)) {
        // no not change attribute
    } else if (readonly) {
        attribute = 'P';
    }

    let genericLov = null;
    if (elementInfo.onLookup !== null) {
        try {
            const { lovName, inputVars, inputFields, returnFields } = JSON.parse(elementInfo.onLookup);
            const inputParams = {
                ...inputVars,
                ...Object.entries(inputFields)
                    .map(([key, val]) => ({[key]: obj?.[mapDto[val]]}))
                    .reduce((acc, el) => ({...acc, ...el}), {}),
                "param.pagemode": 'view',
                ...extraParams,
            }
            genericLov = {
                inputParams,
                returnFields,
                lovName,
                exact: false,
                rentity: master,
            }
        } catch (err) {
            //console.error(err);
        }
    }

    if (!dropdown?.length && elementInfo.elementId === 'department' && !genericLov) {
        dropdown = Object.entries(departments)
            .map(([code, { description }]) => ({ code, desc: description }))
            .sort((a, b) => (a.code > b.code) ? 1 : (a.code < b.code) ? -1 : 0)
    }

    const Component =
            dropdown?.length || options ? EAMSelect
            : genericLov ? EAMAutocomplete
            : ['CODE', 'CODEDESC', 'RENT'].includes(elementInfo.udfLookupType) ? EAMSelect
            : elementInfo.fieldType === 'select' ? EAMSelect
            : elementInfo.udfLookupType === 'NONE' && elementInfo.fieldType === 'text' ? EAMTextField
            : ['number', 'integer', 'currency'].includes(elementInfo.fieldType) ? EAMTextField
            : elementInfo.fieldType === 'datetime' ? EAMDateTimePicker
            : elementInfo.fieldType === 'date' ? EAMDatePicker
            : elementInfo.fieldType === 'textarea' ? EAMTextField
            : elementInfo.fieldType === 'text' ? EAMTextField
            : elementInfo.fieldType === 'checkbox' ? EAMCheckbox
            : null;

    if (!Component) {
        console.error(elementInfo);
        return null;
    }

    if (!udf && !keyCode) {
        return <div style={{color: 'red', width: '100%'}}>{`Field ${elementInfo.elementId} is not supported. Please hide it in the screen layout.`}</div>
    }

    const width = valuesConf[CONFIGURATION_TYPE.WIDTH]?.[0]?.value || 12;

    return  <Grid item sm={+width} xs={12}>
        <Component
            key={keyCode}
            label={skipTranslations ? elementInfo.elementId : elementInfo.text}
            required={['R', 'S'].includes(attribute)}
            style={{width: '100%'}}
            disabled={elementInfo.readonly || attribute === 'P'}
            maxLength={elementInfo.maxLength}
            value={elementInfo.fieldType?.startsWith('date') ? value || '' : value}
            options={['status', 'workorderstatus'].includes(elementInfo.elementId) ? undefined : dropdown?.length ? dropdown : values}
            onChange={(val) => updateF({[keyCode]:
                    elementInfo.fieldType === 'select'
                    || ['CODE', 'CODEDESC', 'RENT'].includes(elementInfo.udfLookupType)
                    || genericLov
                    || dropdown?.length
                    || options
                    ? val?.code : val}
                    , udf ? ['userDefinedFields'] : null
                    , val
                    )
            }
            updateDesc={false}
            errorText={errorMessages?.[keyCode]}
            {...['status', 'workorderstatus'].includes(elementInfo.elementId) ?  {
                    autocompleteHandler: async (hint, config) => {
                        const a =  await WSCaseV2.readStatusList({oldStatus: obj?.[LOG_DTO2.STATUSCODE], rentity }, config);
                            return Promise.resolve({body:{ data:
                            a.body.data.filter(s => !dropdown || dropdown.find(opt => opt.code === s.code))
                        }});
                    },
                    autocompleteHandlerParams: []
                }
                : elementInfo.udfLookupType === 'CODEDESC' ? {
                    autocompleteHandler: () => WSCaseV2.readUdfCodeDescLOV(rentity, keyCode),
                    autocompleteHandlerParams: []
                }
                : elementInfo.udfLookupType === 'CODE' ? {
                    autocompleteHandler: () => WSCaseV2.readUdfCodeLOV(rentity, keyCode),
                    autocompleteHandlerParams: []
                }
                : genericLov !== null ? {
                    autocompleteHandler: (hint, config) => {
                        return WSCaseV2.readGenericLOV({...genericLov, hint}, config);
                    },
                    autocompleteHandlerParams: []
                }
                : {}
            }
            {...autocompleteHandler ? {autocompleteHandler} : {}}
            {...autocompleteHandlerParams ? {autocompleteHandlerParams} : {}}
        />
        </Grid>

}

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 400,
    },
    blockSection: {
        marginTop: '6px',
        padding: '4px',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.main[100]
    },
    blockGrid: {
        margin: 0,
        padding: 0
    }
}));

const ScreenEAMBlock = ({ logbookCode, log, updateObj, errorMessages, panelExpandedMap, setPanelExpandedMap, hasAccessRights, setPanelExpandedComputed, readonly, lang }) => {
    const dispatch = useDispatch();
    const { logbooks, skipTranslations } = useSelector(state => state.application);
    const classes = useStyles();

    const logbookConf = logbooks.find(conf => conf.logbook === logbookCode);

    return Object.entries(logbookConf?.caseScreenFields)
        .map(([block, elementList]) => {
            // const valuesConf = getValuesConf({ customValues: logbookConf?.logbookValues, elementId: block, obj: log, mapDto: LOG_CASE_MAP });
            const blockInfo = logbookConf?.caseScreenBlocks?.[block];

            // const computedBlockAttribute = valuesConf[CONFIGURATION_TYPE.ATTRIBUTE]?.[0]?.value;
            // if (computedBlockAttribute && panelExpandedComputed[block] !== computedBlockAttribute) {
            //     setPanelExpandedComputed((map) => ({...map, [block]: computedBlockAttribute}));
            //     setPanelExpandedMap((map) => ({
            //         ...map,
            //         [block]: computedBlockAttribute === 'E' ? true
            //             : ['C', 'H'].includes(computedBlockAttribute) ? false
            //             : undefined
            //     }));
            // }

            return [blockInfo?.attribute, setPanelExpandedComputed[block]].includes('H') ?
                null
                : <Panel
                    heading={skipTranslations ? block : blockInfo?.text || "Base Log Details"}
                    key={`${block}block`}
                    className={classes.blockSection}
                    defaultExpanded={blockInfo?.attribute !== 'C'}
                    panelExpanded={panelExpandedMap[block]}
                    onPanelChange={(expanded) => setPanelExpandedMap((map) => ({...map, [block]: expanded}))}
                >
                    <Grid container spacing={0} className={classes.blockGrid}>
                        {elementList.map(elementInfo => <EAMInputField {...{ readonly, hasAccessRights, customValues: logbookConf?.logbookValues, key: elementInfo.elementId, mapDto: LOG_CASE_MAP, elementInfo, keyCode: elementInfo.elementId, updateF: updateObj, obj: log, errorMessages, skipTranslations }} />)}
                    </Grid>
                </Panel>
        });
}

export default ScreenEAMBlock;
