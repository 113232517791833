import WS from './WS'

class WSParentLinks {

    getParentLinks(screenCode, caseCode, config = {}) {
        return WS._get(`/parentlinks/${screenCode}/${caseCode}`, config);
    };

    addParentLink = parentlink => {
        return WS._post(`/parentlinks/`, parentlink);
    };

    deleteParentLink = (screenCode, caseCode, parentCode) => {
        return WS._delete(`/parentlinks/${screenCode}/${caseCode}/${parentCode}`);
    };

}

export default new WSParentLinks();