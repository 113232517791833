import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import './RichTextEditor.css';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model'
//Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// // Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
//Plugins

// import 'tinymce/plugins/accordion';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
// import 'tinymce/plugins/autoresize';
// import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
// import 'tinymce/plugins/directionality';
// import 'tinymce/plugins/emoticons';
// import 'tinymce/plugins/fullscreen';
// import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
// import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
// import 'tinymce/plugins/nonbreaking';
// import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
// import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
// import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
// import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
// eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

const editorRef = React.createRef();

const BundledTinyEditor = (props) => {
  const { init, maxHeight, ...rest } = props;
  // note that skin and content_css is disabled to avoid the normal
  // loading process and is instead loaded as a string via content_style
  return (
    <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        init={{
            object_resizing : 'img',
            height: (maxHeight ?? 480) - 80,
            autoresize_overflow_padding: 50,
            plugins: 'advlist anchor autolink charmap code codesample image insertdatetime link lists media media preview quickbars searchreplace table visualblocks wordcount',
                // ...isMaximized ? ['autoresize'] : [],
            menubar: false,
            toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'table | link image | ' +
                'removeformat',
            file_picker_types: 'image',
            branding: false,
            // statusbar: false, // hiding the statusbar prevents the resize
            elementpath: false,
            // automatic_uploads: true,
            file_picker_callback: function (cb, value, meta) {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = function () {
                    var file = this.files[0];
                    var reader = new FileReader();
                    reader.onload = function () {
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        var base64 = reader.result.split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                };
                input.click();
            },
            skin: false,
            content_css: false,
            content_style: [contentCss, contentUiCss, 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'].join('\n'),
        }}
        {...rest}
    />
  );
}

export default BundledTinyEditor;
