import { setPage } from "actions/layoutActions";
import { handleError } from 'react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import Tools from "tools/Tools";
import LogSelection from "./LogSelection";

const mapStateToProps = (state, ownProps) => {
    return {
        styles: state.application.applicationData.logbookStyles[ownProps.match.params.screenCode],
        logbookData: state.application.userData.screens[ownProps.match.params.screenCode],
        logbookCode: ownProps.match.params.screenCode,
        dataspyId:  Tools.getURLParameterByName("dataspy"),
        extraVariables: state.application.applicationData.extraVariables,
        applicationData: state.application.applicationData,
    }
};

const LogSelectionContainer = connect(
    mapStateToProps, {
        setPage,
        handleError
    }
)(LogSelection);

export default LogSelectionContainer;