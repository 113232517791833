import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import "core-js";
import { enGB } from "date-fns/locale";
import { polyfill } from 'es6-promise';
import { create } from "jss";
import LogbookContainer from "LogbookContainer";
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from "react-redux";
import "regenerator-runtime";
import AuthWrapper from "./AuthWrapper";
import "./index.css";
import InitLogbook from "./InitLogbook";
import { unregister } from "./registerServiceWorker";
import store from "./store";

const jss = create(jssPreset());
polyfill();

loadScript(process.env.REACT_APP_FRONTEND + "ckeditor/ckeditor.js")

ReactDOMClient.createRoot(document.getElementById('root'))
    .render(
        <AuthWrapper>
            <StylesProvider jss={jss}>
                <Provider store={store}>
                    <LocalizationProvider  dateAdapter={AdapterDateFns} locale={enGB}>
                        <InitLogbook>
                            <LogbookContainer />
                        </InitLogbook>
                    </LocalizationProvider>
                </Provider>
            </StylesProvider>
        </AuthWrapper>
    );

unregister();

function loadScript(src) {
    var tag = document.createElement("script");
    tag.defer = true;
    tag.src = src;
    document.body.appendChild(tag);
}
