import React from 'react';
import LogbookDrawer from "ui/components/drawer/LogbookDrawer";

const drawerWidth = 240;

class LogbookPage extends React.Component {
    componentDidMount() {
        this._setLogbook({});
    }

    componentDidUpdate(prevProps) {
        this._setLogbook(prevProps);
    }

    _setLogbook = (props) => {
        const { logbookCode, setLogbook } = this.props;
        if (props.logbookCode !== logbookCode) {
            setLogbook(logbookCode);
        }
    };

    render() {
        const { logbookDesc, showError, showSuccess, open, handleDrawerClose, history, logbookCode, logbooks, userData, applicationData, skipTranslations, setSkipTranslations } = this.props;

        return <LogbookDrawer
            drawerWidth={drawerWidth}
            open={open}
            handleDrawerClose={handleDrawerClose}
            logbookDesc={logbookDesc}
            showError={showError}
            showSuccess={showSuccess}
            history={history}
            logbookCode={logbookCode}
            logbooks={logbooks}
            userData={userData}
            applicationData={applicationData}
            setSkipTranslations={setSkipTranslations}
            skipTranslations={skipTranslations}
        />
    }
}

export default LogbookPage;