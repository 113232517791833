const ROUTES_BASE = {
    HOME: '',
    ADMIN: 'admin',
    LOGBOOK_BASE: ':screenCode',
    LOGBOOKV2_BASE: ':logbookCode',
    RELEASE_NOTES: 'releasenotes',
}

ROUTES_BASE.LOGBOOK_GRID = `${ROUTES_BASE.LOGBOOK_BASE}/logs`;
ROUTES_BASE.LOGBOOK_LOG = `${ROUTES_BASE.LOGBOOK_BASE}/log/:logNumber?`;
ROUTES_BASE.LOGBOOK_LOGHISTORY = `${ROUTES_BASE.LOGBOOK_BASE}/history/:logNumber`;
// V2
ROUTES_BASE.LOGBOOK_MAIN = `${ROUTES_BASE.LOGBOOKV2_BASE}/main`;
ROUTES_BASE.LOGBOOKV2_LOG = `${ROUTES_BASE.LOGBOOKV2_BASE}/log2`;
ROUTES_BASE.LOGBOOK_EDITLOGV2 = `${ROUTES_BASE.LOGBOOKV2_LOG}/:logNumber`;
ROUTES_BASE.LOGBOOK_CREATELOGV2 = `${ROUTES_BASE.LOGBOOKV2_LOG}/create`;
ROUTES_BASE.LOGBOOK_CLONELOGV2 = `${ROUTES_BASE.LOGBOOK_EDITLOGV2}/clone`;

const applyToFields = (obj, func) => Object.entries(obj).map(([key, value]) => [key, func(value)]).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

const ROUTES = applyToFields(ROUTES_BASE, (route) => `${process.env.REACT_APP_FRONTEND}${route}`);

export {
    ROUTES,
}
