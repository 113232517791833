import { Button, Chip, Grid } from '@mui/material';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import React from 'react';
import BlockUi from 'react-block-ui';
import { generatePath } from 'react-router';
import Tools from "tools/Tools";
import WSCaseV2 from 'tools/rest/WSCaseV2';
import WS from 'tools/rest/WSParentLinks';
import Panel from 'ui/components/Panel';

class ParentEvents extends React.Component {

    state = {
        parentLinks: []
    };

    componentDidMount = () => {
        this.loadParentLinks();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.log?.code !== this.props.log?.code || this.props.logNumber !== prevProps.logNumber) {
            this.loadParentLinks();
        }
    };

    loadParentLinks() {
        this.setState(() => ({
            loading: true
        }));

        WS.getParentLinks(this.props.logbookCode, this.props.log?.code || this.props.logNumber)
            .then(response => {
                this.setState(() => ({
                    parentLinks: response.body.data,
                    loading: false
                }));
            }).catch(error => {
            this.props.handleError(error);
            this.setState(() => ({
                loading: false
            }));
        })
    }

    addParentLink = () => {
        if (this.state.parentCode) {
            WS.addParentLink({
                screen: '*',
                code: this.props?.log.code || this.props.logNumber,
                parent: this.state.parentCode
            }).then(response => {
                this.setState(() => ({
                    parentLinks: response.body.data,
                    loading: false,
                    parentCode: null
                }));
            }).catch(error => {
                this.props.handleError(error);
                this.setState(() => ({
                    loading: false
                }));
            })
        }
    };

    deleteParentLink = (parentLink) => {
        WS.deleteParentLink('*', this.props.log?.code || this.props.logNumber, parentLink.parent)
            .then(response => {
                this.setState(() => ({
                    parentLinks: response.body.data,
                    loading: false,
                    parentCode: null
                }));
            }).catch(error => {
                this.props.handleError(error);
                this.setState(() => ({
                    loading: false
                }));
        })
    };

    render() {
        const { logbookCode } = this.props;

        const userCanModify = !this.props.readonly;

        return (
            <BlockUi tag="div" blocking={this.state.loading} style={{height: "100%", width: "100%", padding: '8px 16px 16px'}}>
                <Grid container spacing={8}>
                    {userCanModify && (
                        <React.Fragment>
                            <Grid item xl={8} xs={8}>
                                <EAMAutocomplete
                                    label={"Case"}
                                    autocompleteHandler={(hint) => WSCaseV2.readCases({ hint, logbookCode })}
                                    elementInfo={{
                                        attribute: 'O',
                                        xpath: "woToLink",
                                    }}
                                    value={{code: this.state.parentCode ||''}}
                                    onChange={(opt) => this.setState((prevState) => ({parentCode: opt?.desc ? opt?.code : opt?.code ? prevState.parentCode : ''}))}
                                    valueKey="parentCode"
                                />

                            </Grid>
                            <Grid item xl={4} xs={4}>
                                <Button onClick={this.addParentLink}
                                        style={{width: "100%", height: "100%", fontSize: 16}}
                                        disabled={!this.state.parentCode}
                                    >
                                    Add parent
                                </Button>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xl={12} xs={12}>
                        {this.state.parentLinks.map((parentLink, index) => {
                            return (
                                <Chip
                                    key={index}
                                    label={parentLink.parent}
                                    onClick={() => this.props.history.push(generatePath(ROUTES.LOGBOOK_EDITLOGV2, {logbookCode: this.props.logbookCode, logNumber: parentLink.parent}))}
                                    onDelete={userCanModify ? () => this.deleteParentLink(parentLink) : undefined}
                                />
                            )
                        })}
                    </Grid>
                </Grid>
            </BlockUi>
        );
    }
}

export default ParentEvents;