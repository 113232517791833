
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ROUTES } from 'Routes';
import Comments from 'eam-components/dist/ui/components/comments/Comments';
import CustomFields from 'eam-components/dist/ui/components/customfields/CustomFields';
import useFieldsValidator from 'eam-components/dist/ui/components/inputs-ng/hooks/useFieldsValidator';
import EISPanel from 'eam-components/dist/ui/components/panel';
import { LOG_CASE_MAP, LOG_DTO2, LOG_WRAPPER_DTO } from 'enums/LogDTO';
import queryString from 'query-string';
import { useEffect, useMemo, useRef, useState } from 'react';
import BlockUi from 'react-block-ui';
import { handleError, openConfirmDialog, showError, showSuccess } from 'react-eis-components/actions/uiActions';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import WSCaseV2 from 'tools/rest/WSCaseV2';
import EDMSDoclightIframe from 'ui/components/elements/EDMSDoclightIframe';
import InfoPage from 'ui/components/infopage/InfoPage';
import LogToolbar from 'ui/components/toolbar/LogToolbar';
import AuditDialog from '../LogDetails/panels/AuditDialog';
import ConnectionsPanel from '../LogDetails/panels/ConnectionsPanel';
import Links from '../LogDetails/panels/Links';
import ParentEvents from '../LogDetails/panels/ParentEvents';
import Watchers from '../LogDetails/panels/Watchers';
import FollowUPWorkOrders from '../LogDetails/panels/followup/FollowUPWorkOrders';
import ScreenEAMBlock, { CONFIGURATION_TYPE, getLogCaseKey, getValuesConf, isUdf } from '../LogMain/ScreenEAMBlock';
import CRYOPositionCapture from './CRYOPositionCapture';
import EventDetail from './EventDetail';
import EventDetailCK from './EventDetailCK';
import EventDetailTiny from './EventDetailTiny';

const CF_BLOCK = 'block_7';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: '100%',
        padding: 0,
        margin: 0,
        backgroundColor: "#eee",
        "-webkit-overflow-scrolling": "touch",
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
    },
    loading: {
        color: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    blockSection: {
        marginBottom: '6px',
        padding: '4px',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.main[100]
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    width: '100%',
                    boxSizing: 'border-box',
                    margin: 0,
                    '&.MuiGrid-item': {
                        boxSizing: 'border-box',
                        margin: '0px',
                        padding: '8px 0 0 8px',
                    },
                },
            },
        },
    }
}));

export const assignDefaults = ({ screenLayout, mapDto }) => ({
    ...Object.values(screenLayout)
        .flat()
        // Keep null default/false default values to allow assignment from URL
        // .filter(elementInfo => elementInfo.defaultValue)
        .filter(elementInfo => !isUdf(elementInfo))
        .filter(elementInfo => getLogCaseKey(elementInfo, mapDto))
        .map(elementInfo => ({ [getLogCaseKey(elementInfo, mapDto)]: elementInfo.defaultValue}))
        .reduce((acc, obj) => ({...acc, ...obj}), {}),
    [LOG_DTO2.USERDEFINEDFIELDS]: Object.values(screenLayout)
        .flat()
        .filter(elementInfo => isUdf(elementInfo))
        .map(elementInfo => ({ [elementInfo.elementId]: elementInfo.defaultValue}))
        .reduce((acc, obj) => ({...acc, ...obj}), {}),
})


const loadLogV2 = async ({ dispatch, logNumber, setLoading }) => {
    try {
        setLoading(true);
        const caseMgmt = await WSCaseV2.readCase(logNumber);
        return caseMgmt;
    } catch (err) {
        dispatch(handleError(err));
        return null;
    } finally {
        setLoading(false);
    }
};

const deleteHandler = async ({ history, logNumber, setLoading, log, match, dispatch, generateErrorMessagesFromException }) => {
    setLoading(true);
    try {
        await WSCaseV2.deleteCase(logNumber);
        dispatch(showSuccess(`Log ${logNumber} deleted successfully.`));
        history.push(generatePath(ROUTES.LOGBOOK_MAIN, match.params));
    } catch (err) {
        generateErrorMessagesFromException(err.response.body.errors);
        dispatch(handleError(err));
    } finally {
        setLoading(false);
    }
}

const saveHandler = ({ logbookCode, load, match, history, validateFields, dispatch, setLoading, generateErrorMessagesFromException }) => async ({log, eventDetails}) => {
    const isValid = validateFields();
    if (!isValid) {
        dispatch(showError("Please confirm all fields are properly filled in before submitting"));
        return;
    }
    setLoading(true);
    try {
        const logCode = log[LOG_DTO2.CODE];
        const logWrapped = {
            [LOG_WRAPPER_DTO.EAM_CASE]: {
                ...log,
                userDefinedFields: {
                    ...log?.userDefinedFields ?? [],
                    udfchar05: logbookCode,
                },
            },
            [LOG_WRAPPER_DTO.EVENT_DETATILS]: eventDetails,
        }
        if (logCode) {
            await WSCaseV2.updateCase(log[LOG_DTO2.CODE], logWrapped);
            dispatch(showSuccess('Log updated successfully.'));
            load();
        } else {
            const code = await WSCaseV2.createCase(logWrapped);
            dispatch(showSuccess(`Log ${code} created successfully.`));
            history.push(generatePath(ROUTES.LOGBOOK_EDITLOGV2, {...match.params, logNumber: code }))
        }
    } catch (err) {
        generateErrorMessagesFromException(err.response.body.errors);
        dispatch(handleError(err));
    } finally {
        setLoading(false);
    }
}

export const getNewObject = (object, value, path, merge = true) =>
    path?.length ?
        {
            ...(object || {}),
            [path[0]]: getNewObject(object[path[0]], value, path.slice(1), merge),
        }
        : merge && typeof value === 'object' && typeof object === 'object' ?
        {
                ...(object || {}),
                ...(value || {}),
        }
        : value;

export const mergeObjWithQueryParams = (obj, queryParams) => {
    const availableParams = Object.keys(obj).filter(e => e !== LOG_DTO2.USERDEFINEDFIELDS);
    const availableUDFParams = Object.keys(obj[LOG_DTO2.USERDEFINEDFIELDS] || {});
    return {
        ...obj,
        ...filterObjectByKeys(availableParams, queryParams),
        [LOG_DTO2.USERDEFINEDFIELDS]: filterObjectByKeys(availableUDFParams, queryParams)
    };
}

export const filterObjectByKeys = (keys, obj) =>
    keys.reduce((acc, key) => ({ ...acc, ...(obj[key] && { [key]: obj[key] }) }), {});

const CERN_BLOCKS = {
    EVENT_DETAILS: 'EVENT_DETAILS',
    EVENT_DETAILS_TUI: 'EVENT_DETAILS_TUI',
    EVENT_DETAILS_CK: 'EVENT_DETAILS_CK',
    COMMENTS: 'COMMENTS',
    DOCUMENTS: 'DOCUMENTS',
    LINKS: 'LINKS',
    PARENT_EVENTS: 'PARENT_EVENTS',
    FOLLOW_UPS: 'FOLLOW_UPS',
    CONNECTIONS: 'CONNECTIONS',
    WATCHERS: 'WATCHERS',
    CRYO_POSITION: 'CRYO_POSITION',
}

const WrapInPanel = ({ block, maximizedPanel, setMaximizedPanel, children, defaultExpanded, panelExpanded, onPanelChange, skipTranslations }) =>
    <EISPanel
        key={block.blockName}
        heading={skipTranslations ? block.blockName : block.lbcHeader}
        defaultExpanded={defaultExpanded}
        detailsStyle={{
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
            height: '100%',
            justifyContent: "space-between",
            flexWrap: "wrap",
        }}
        summaryStyle={{fontSize: 14}}
        headingBar={
            !setMaximizedPanel ? null
            : maximizedPanel === block.blockName
            ? <IconButton style={{marginLeft: 'auto'}} onClick={(e) => { e.stopPropagation(); setMaximizedPanel(''); }} size="large"><FullscreenExit /></IconButton>
            : <IconButton style={{marginLeft: 'auto'}} onClick={(e) => { e.stopPropagation(); setMaximizedPanel(block.blockName) }} size="large"><Fullscreen /></IconButton>
        }
        panelExpanded={panelExpanded}
        onPanelChange={onPanelChange}
    >
        {children}
    </EISPanel>

const LogPageV2 = ({ match, history, mode, location }) => {
    const dispatch = useDispatch();
    const [log, setLog] = useState({});
    const [eventDetails, setEventDetails] = useState('');
    const [loading, setLoading] = useState(false);
    const [panelExpandedMap, setPanelExpandedMap] = useState({});
    const [panelExpandedComputed, setPanelExpandedComputed] = useState({});
    const [closedCase, setClosedCase] = useState(false);
    const { logbooks, applicationData, userData, skipTranslations } = useSelector(state => state.application);
    const [auditOpen, setAuditOpen] = useState(false);
    const classes = useStyles();

    const ref = useRef(null);
    const maxHeightRef = ref?.current?.offsetHeight;

    const { logbookCode, logNumber } = match?.params;

    const logbookConf = logbooks.find(conf => conf.logbook === logbookCode);

    if (!logbookConf) {
        return <InfoPage
                title="No logbook found"
                message={`Requested logboook ${logbookCode} not found or you don't have access.`}
            />
    }

    const hasAccessRights = logbookConf.accessRights === 'W';
    const readonly =  !hasAccessRights || closedCase;

    const load = async (clone = false) => {
        const logWrapper = await loadLogV2({dispatch, logNumber, setLoading});
        const logCase = logWrapper?.[LOG_WRAPPER_DTO.EAM_CASE];
        const logLogbook = logCase?.[LOG_DTO2.USERDEFINEDFIELDS]?.udfchar05;
        if (logLogbook && logbookCode !== logLogbook){
            history.push(generatePath(ROUTES.LOGBOOK_EDITLOGV2, {...match.params, logbookCode: logLogbook}));
        } else if (logCase) {
            setLog({
                ...logCase,
                [LOG_DTO2.CODE]: clone ? '' : logCase?.[LOG_DTO2.CODE]
            });
            setClosedCase(logCase[LOG_DTO2.SYSTEMSTATUSCODE] === 'C');
            setEventDetails(logWrapper[LOG_WRAPPER_DTO.EVENT_DETATILS]);
            eqCode.current = logCase?.[LOG_DTO2.EQUIPMENTCODE] ?? '';
        }
    }

    const queryParams = useMemo(() => queryString.parse(location.search, { parseBooleans: true }), [location.search]);

    const [maximizedPanel, setMaximizedPanel] = useState(queryParams?.maximize);

    const eqCode = useRef(null);

    useEffect(() => {
        [...Object.keys(logbookConf?.caseScreenFields), ...Object.keys(CERN_BLOCKS)]
            .forEach((block) => {
                const valuesConf = getValuesConf({ customValues: logbookConf?.logbookValues, elementId: block, obj: log, mapDto: LOG_CASE_MAP });
                const computedBlockAttribute = valuesConf[CONFIGURATION_TYPE.ATTRIBUTE]?.[0]?.value;

                if (computedBlockAttribute && panelExpandedComputed[block] !== computedBlockAttribute) {
                    setPanelExpandedComputed((map) => ({...map, [block]: computedBlockAttribute}));
                    setPanelExpandedMap((map) => ({
                        ...map,
                        [block]: computedBlockAttribute === 'E' ? true
                            : ['C', 'H'].includes(computedBlockAttribute) ? false
                            : undefined
                    }));
                }
            });
    }, [log]);

    useEffect(() => {
        logNumber && load(mode === 'CLONE');
        if (mode === 'CREATE') {
            setLog(
                mergeObjWithQueryParams(assignDefaults({ screenLayout: logbookConf?.caseScreenFields, mapDto: LOG_CASE_MAP }), queryParams)
            );
            setEventDetails('');
            eqCode.current = '';
        }
    }, [logbookCode, logNumber, mode, queryParams]);


    const updateObj = (obj, path) => setLog((updatedLog) => getNewObject(updatedLog, obj, path));

    const equipmentCode = log?.[LOG_DTO2.EQUIPMENTCODE];
    useEffect(() => {
        if (eqCode.current == null) {
            return;
        }
        if (equipmentCode && equipmentCode !== eqCode.current) {
            eqCode.current = equipmentCode;
            (async () => {
                try {
                    const equipment = await WSCaseV2.readEquipment({ equipmentCode });
                    updateObj({
                        [LOG_DTO2.LOCATIONCODE]: equipment?.hierarchyLocationCode,
                        [LOG_DTO2.DEPARTMENTCODE]: equipment?.departmentCode,
                    })
                } catch (err) {
                    console.error(err);
                }
            })()
        }
    }, [equipmentCode]);

    const flatFields = useMemo(() =>
        Object.values(logbookConf?.caseScreenFields).flat()
            .reduce((acc, el) => ({...acc, [/EAMID_[^_]*_(Standard)?UserDefinedFields_.*/.test(el?.xpath) ? el?.elementId : LOG_CASE_MAP[el?.elementId]]: el}), {})
        , [logbookConf]);

    const flatLog = useMemo(() => ({
        ...log,
        ...log?.userDefinedFields,
    }), [log]);

    const { errorMessages, validateFields, resetErrorMessages, generateErrorMessagesFromException } = useFieldsValidator(flatFields, flatLog);

    if (Object.entries(log || {}).length === 0 && logNumber) {
        return <div className={classes.loading}>
            <Typography variant="h4">Loading...</Typography>
        </div>
    }

    const props = {
        handleError: (...props) => dispatch(handleError(...props)),
        showError: (...props) => dispatch(showError(...props)),
        logbookCode,
        logNumber,
        userData,
        panels: {},
        onPanelChange: () => 1,
        match,
        history,
        applicationData,
        canUpdate: true,
        log,
        updateObj,
    }

    const getComponentByBlock = ( block, maxHeight ) => {
        const blockProps = {
            key: block.name,
            name: block.lbcHeader,
            readonly,
            skipTranslations,
        }

        const panelProps = {
            name: block.lbcHeader,
            maximizedPanel,
            setMaximizedPanel,
            defaultExpanded: block.attribute !== 'C',
            panelExpanded: panelExpandedMap[block.blockName],
            onPanelChange: (expanded) => setPanelExpandedMap((map) => ({...map, [block.blockName]: expanded})),
            skipTranslations,
        };

        if (!log?.[LOG_DTO2.CODE] && !block.showOnNew) {
            return null;
        }

        let Comp = null;
        switch (block.blockName) {
            case CERN_BLOCKS.EVENT_DETAILS:
                Comp = <EventDetailTiny
                        {...blockProps}
                        data={eventDetails}
                        onChange={setEventDetails}
                        maxHeight={maximizedPanel ? maxHeight : 580}
                        readonly={readonly}
                    />
                break;
            case CERN_BLOCKS.EVENT_DETAILS_TUI:
                Comp = <EventDetail
                    {...blockProps}
                    data={eventDetails}
                    onChange={setEventDetails}
                />
                break;
            case CERN_BLOCKS.EVENT_DETAILS_CK:
                Comp = <EventDetailCK
                    {...blockProps}
                    data={eventDetails}
                    onChange={setEventDetails}
                />
                break;
            case CERN_BLOCKS.COMMENTS:
                Comp = <Comments
                        entityCode="CASE"
                        entityKeyCode={logNumber}
                        userCode={userData.eamAccount.userCode}
                        userDesc={userData.eamAccount.userDesc}
                        handleError={(...props) => dispatch(handleError(...props))}
                    // onCommentAdded={(comment) => this.onCommentChange({added: true}, comment)}
                    // onCommentUpdated={(comment) => this.onCommentChange({updated: true}, comment)}
                    // entityOrganization={equipment?.organization}
                    />
                break;
            case CERN_BLOCKS.DOCUMENTS:
                Comp = <EDMSDoclightIframe
                        key={block.blockName}
                        objectID={logNumber}
                        objectType="CASE"
                        mode="write"
                        edmsdocLightLink={applicationData.edmsdocLightLink}
                        profile={applicationData.edmsdocLightProfile}
                        title={''}
                    />
                break;
            case CERN_BLOCKS.LINKS:
                Comp = <Links
                    {...props}
                    {...blockProps}
                    openConfirmDialog={(...props) => dispatch(openConfirmDialog(...props))}
                />
                break;
            case CERN_BLOCKS.PARENT_EVENTS:
                Comp = <ParentEvents
                    {...props}
                    {...blockProps}
                />
                break;
            case CERN_BLOCKS.FOLLOW_UPS:
                Comp = <FollowUPWorkOrders
                    {...props}
                    {...blockProps}
                    v2={true}
                />
                break;
            case CERN_BLOCKS.CONNECTIONS:
                Comp = <ConnectionsPanel
                    {...props}
                    {...blockProps}
                />
                break;
            case CERN_BLOCKS.WATCHERS:
                Comp = <Watchers
                    {...props}
                    {...blockProps}
                />
                break;
            case CERN_BLOCKS.CRYO_POSITION:
                Comp = <CRYOPositionCapture
                    {...props}
                    {...blockProps}
                    logbookConf={logbookConf}
                    errorMessages={errorMessages}
                />
                break;
            default:
                Comp = null;
        }
        return <WrapInPanel
            key={block.blockName}
            {...panelProps}
            block={block}
            maxHeight={maxHeight}
            skipTranslations={skipTranslations}
        >
        {Comp}
        </WrapInPanel>;
    }

    const cfBlock = logbookConf?.caseScreenBlocks?.[CF_BLOCK];

    return (
        <BlockUi className={classes.root} blocking={loading} style={{width: '100%', heigth: '100%' }}>
            <LogToolbar
                log={log}
                logNumber={logNumber}
                newEntity={!!mode}
                reload={() => load()}
                saveHandler={() => saveHandler({ logbookCode, load, match, history, validateFields, dispatch, setLoading, generateErrorMessagesFromException })({ log, eventDetails })}
                newHandler={() => history.push(generatePath(ROUTES.LOGBOOK_CREATELOGV2, match.params))}
                cloneHandler={() => {
                    history.push(generatePath(ROUTES.LOGBOOK_CLONELOGV2, match.params));
                }}
                auditHandler={() => setAuditOpen(true)}
                deleteHandler={() => deleteHandler({ history, logNumber, setLoading, log, match, dispatch, generateErrorMessagesFromException })}
                openConfirmDialog={(...props) => dispatch(openConfirmDialog(...props))}
                url={`${window.origin}${generatePath(ROUTES.LOGBOOK_CREATELOGV2, match.params)}`}
                logbookCode={logbookCode}
                readonly={!hasAccessRights}
                closedCase={closedCase}
                reportUrl={applicationData.reportUrl}
            />
            <Grid ref={ref} container spacing={8} style={{ width: '100%', height: '100%', overflowY: "auto", padding: '8px', margin: 0, marginTop: '2px', boxSizing: 'border-box'}}>
                { !maximizedPanel ?
                    <>
                        <Grid item md={6} sm={12} style={{ margin: 0, padding: '8px', overflow: 'visible' }}>
                            <ScreenEAMBlock
                                log={log}
                                updateObj={updateObj}
                                errorMessages={errorMessages}
                                logbookCode={logbookCode}
                                panelExpandedMap={panelExpandedMap}
                                setPanelExpandedMap={setPanelExpandedMap}
                                setPanelExpandedComputed={setPanelExpandedComputed}
                                readonly={readonly}
                                hasAccessRights={hasAccessRights}
                                closedCase={closedCase}
                                lang={queryParams?.lang}
                            />
                            {
                                ['O', 'C'].includes(cfBlock?.attribute) ?
                                    <WrapInPanel
                                        {
                                            ...{
                                                name: cfBlock.text || "Custom Fields",
                                                defaultExpanded: cfBlock.attribute !== 'C',
                                                panelExpanded: panelExpandedMap['EAM_CUSTOMFIELDS'],
                                                onPanelChange: (expanded) => setPanelExpandedMap((map) => ({...map, ['EAM_CUSTOMFIELDS']: expanded})),
                                                block: {
                                                    lbcHeader: cfBlock.text || 'Custom Fields'
                                                },
                                                maxHeight: maxHeightRef,
                                            }
                                        }
                                    >
                                        <CustomFields
                                            entityCode='CASE'
                                            entityKeyCode={logNumber}
                                            classCode={log[LOG_DTO2.CLASSCODE]}
                                            customFieldMap={log[LOG_DTO2.CLASSCODE]}
                                            fetchCustomFields={true}
                                            register={(cfCode) => ({
                                                    value: log[LOG_DTO2.CUSTOM_FIELDS_MAP]?.[cfCode],
                                                    onChange: (val = '') => setLog((log) => getNewObject(log, {[cfCode]: val?.code ? val.code : val}, [LOG_DTO2.CUSTOM_FIELDS_MAP])),
                                                })

                                            }
                                        />
                                    </WrapInPanel>
                                    : null
                            }
                        </Grid>
                        <Grid item md={6} sm={12} style={{ margin: 0, padding: '8px', overflow: 'visible' }}>
                            {Object.values(logbookConf?.logbookBlocks)
                                    .sort((a, b) => a.blockOrder - b.blockOrder)
                                    .filter(block => block.blockActive)
                                    .filter(block => panelExpandedComputed[block?.blockName] !== 'H')
                                    .map(block => getComponentByBlock(block))
                            }
                        </Grid>
                    </>
                    : <Grid item md={12} sm={12} style={{height: '100%', width: '100%', overflow: 'visible'}}>
                        {
                            Object.values(logbookConf?.logbookBlocks)
                                .sort((a, b) => a.blockOrder - b.blockOrder)
                                .filter(block => block.blockActive)
                                .filter(block => block.blockName === maximizedPanel)
                                .map(block => getComponentByBlock(block, maxHeightRef))
                        }
                    </Grid>
                }
            </Grid>
            <AuditDialog open={auditOpen}
                         handleClose={() => setAuditOpen(false)}
                         screenFields={logbookConf?.caseScreenFields}
                         caseCode={log.caseCode}
            />
        </BlockUi>
    )
}

export default LogPageV2;