import { initializeApplication } from "./actions/actions";
import { useSelector, useDispatch } from "react-redux";
import { getInitData } from 'actions/applicationActions'
import { useEffect } from "react";

const InitLogbook = ({ children }) => {
    const dispatch = useDispatch();
    const { applicationData, userData } = useSelector(state => state.application);
    useEffect(() => {
        dispatch(getInitData());
    }
    , []);
    return children;
};

export default InitLogbook;
