import React from 'react';
import WS from '../../../tools/rest/WS';
import EISTable from 'react-eis-components/ui/components/table'

class History extends React.Component {

    state = {
        history: []
    }

    componentDidMount = () => {
        this.setState(() => ({
            loading: true
        }));

        WS.getCaseHistory(this.props.logNumber)
            .then(response => {
                this.setState(() => ({
                    history: response.body.data,
                    loading: false
                }));
            }).catch(error => {
                this.props.handleError(error);
                this.setState(() => ({
                    loading: false
                }));
        })
    }

    beautifyOperationCode = code => {
        if (code.endsWith("U")) {
            return "Update";
        }

        if (code.endsWith("C")) {
            return "Creation";
        }
    }

    prepareDataForTable = () => {
        return this.state.history.map(element => ({
            date: new Date(element.timestamp).toTimeString(),
            user: element.user,
            type: this.beautifyOperationCode(element.type),
            data: element.operation
        }))
    }

    render() {
        return (
            <EISTable
                propCodes={["date", "user", "type", "data"]}
                headers={["Date/Time", "User", "Operation type", "Data"]}
                data={this.prepareDataForTable()}
            />
        );
    }
}

export default History;