import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Checkbox,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormControl,
    FormControlLabel
} from '@mui/material';
import { FilterNone } from '@mui/icons-material';
import queryString from 'query-string';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class ParametrizedURLDialog extends React.Component {
    state = {
        paramsSelected: undefined
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.paramsSelected) {
          return {
            paramsSelected: Object.keys(props.params)
          };
        }
        return null;
      }

    handleSelectAll = () =>
        this.isAllChecked()
        ? this.setState({ paramsSelected: [] })
        : this.setState({ paramsSelected: this.getAllParamKeys() })


    handleSelectParam = (param) => () => {
        const { paramsSelected } = this.state;
        paramsSelected.includes(param)
        ? this.setState({ paramsSelected: paramsSelected.filter(p => p !== param ) })
        : this.setState({ paramsSelected: [...paramsSelected, param] })
    }

    getParametrizedURL = () => {
        const { paramsSelected } = this.state;
        const { params, url } = this.props;
        return queryString.stringifyUrl({
            url,
            query: paramsSelected.reduce((acc, param) => ({ ...acc, [param]: params[param] }), {})
        });
    }

    getAllParamKeys = () => Object.keys(this.props.params)

    isAllChecked = () => {
        const { paramsSelected } = this.state;
        return this.getAllParamKeys().length === paramsSelected.length
    }

    render() {
        const { title, open, onClose, params = {} } = this.props;
        const { paramsSelected } = this.state;
        const parametrizedURL = this.getParametrizedURL();
        return (
            <Dialog
                fullWidth
                scroll="paper"
                open={open}
                onClose={onClose}
                >
                <DialogTitle>
                    <p>{title}</p>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            labelWidth={0}
                            disabled
                            value={parametrizedURL}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CopyToClipboard text={parametrizedURL}>
                                        <IconButton>
                                            <FilterNone />
                                        </IconButton>
                                    </CopyToClipboard>
                                </InputAdornment>
                            }
                            />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            paddingTop: '10px',
                            paddingRight: '30px'
                        }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={this.isAllChecked()}
                                    onChange={this.handleSelectAll}
                                    />
                                }
                                labelPlacement="start"
                                label="All parameters"
                            />
                        </div>
                    </FormControl>
                </DialogTitle>
                <DialogContent>
                    <List dense>
                        {Object.keys(params).map(param => (
                            <ListItem key={param} button onClick={this.handleSelectParam(param)}>
                                <ListItemText primary={param} secondary={params[param]} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={this.handleSelectParam(param)}
                                        checked={paramsSelected.includes(param)}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ParametrizedURLDialog
