import { connect } from 'react-redux'
import { handleError } from 'react-eis-components/actions/uiActions';
import History from './History'

const mapStateToProps = (state, ownProps) => {
    return {
        logNumber: ownProps.match.params.logNumber
    }
};

const HistoryContainer = connect(
    mapStateToProps, {
        handleError
    }
)(History);

export default HistoryContainer;