import FontIcon from '@mui/material/Icon';
import { Paper, Typography } from '@mui/material';
import React from 'react';

export default class InfoPage extends React.Component
{
    mainDivStyle = {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fafafa"
    };

    fontIconStyle = {
        fontSize: 30,
        color: "darkblue",
        margin: 10
    };

    paperStyle = {
        padding: 15,
        margin: 10
    };

    render() {
        return (
            (
                <div style={this.mainDivStyle}>
                    <Paper style={this.paperStyle}>
                        <Typography type="headline" component="h3">
                            <FontIcon style={this.fontIconStyle} className="fa fa-user-times" />
                            {this.props.title}
                        </Typography>
                        <Typography component="p">
                            {this.props.message}
                        </Typography>
                    </Paper>
                </div>
            )
        )
    }
}

