import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import GridWS from 'eam-components/dist/ui/components/eamgrid/lib/GridWS';
import {useState, useEffect} from "react"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const AuditRow = props => {
    const {auditEntry} = props;
    return (
        <div style={{display: "flex", alignItems: "center", borderBottom: "1px solid #fafafa", margin: 10, paddingBottom: 10}}>
            <div style={{width: 200}}>{auditEntry.fielddesc}</div>
            <div style={{width: 200}}>{auditEntry.oldvalue}</div>
            <ArrowForwardIosIcon style={{color: "#7d7d7d"}}/>
            <div style={{width: 200}}>{auditEntry.newvalue}</div>
            <div>{auditEntry.changedby} ({auditEntry.changedate})</div>
        </div>
    )
}

const AuditDialog = props => {

const {open, handleClose, caseCode} = props;
const [data, setData] = useState([])

useEffect( () => {
    if (!open) {
        return
    }

    const request = {
        gridName: "BSAUDP",
        userFunctionName: "CSCASE",
        gridType: "LOV",
        params: {
            "param.tablename": "R5CASEMANAGEMENT",
            "param.primaryid": caseCode
        },
        gridSort: [{sortBy: "changedate", sortType: "DESC"}]
    }

    GridWS.getGridData(request)
    .then(response => setData(response.body.data.row.map(row => row.cell.reduce((obj, cell) => Object.assign(obj, { [cell.t]: cell.value }), {}))))
    .catch(console.error)
}, [open])

return (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>History</DialogTitle>
        <DialogContent>
            <div style={{fontSize: 14}}>
                {data.map(auditEntry => (<AuditRow auditEntry={auditEntry}/>))}
            </div>
        </DialogContent>
    <DialogActions>
        <Button onClick={handleClose}>Close</Button>
    </DialogActions>
    </Dialog>
)

}

export default AuditDialog;