import { Add, Delete, Edit } from '@mui/icons-material';
import { Button, Divider, Grid, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import WSLinks from "tools/rest/WSLinks";
import AddLinkDialog from "./AddLinkDialog";

const styles = {
    root: {},
    link: {
        '&:hover': {
            backgroundColor: "#eaeaea",
            cursor: "pointer"
        },
        display: "flex",
        justifyContent: "space-between"
    },
    insideLinkDiv: {
        width: "40%",
        display: "flex",
        flexDirection: "column",
        wordBreak: "break-word"
    },
    icons: {

    }
};

export const CI_INSTRUM_LOGBOOK = 'CI_INSTRUM';

export const AFT_LINK = "https://aft.cern.ch/faults/";
export const JIRA_LINK = "https://its.cern.ch/jira/browse/";
export const SNOW_LINK = "https://cern.service-now.com/task.do?sysparm_query=number=";
export const IMPACT_LINK = "https://impact.cern.ch/impact/secure/?place=editActivity:";
export const CI_INSTR_LINK = "https://apex-sso.cern.ch/pls/htmldb_edmsdb/f?p=177:1:30123577607364::::P1_PATTERN:";

class Links extends React.Component {

    state = {
        links: [],
        dialogOpen: false
    };

    componentDidMount = () => {
        this.loadLinks();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.log?.code !== this.props.log?.code || prevProps.logNumber !== this.props.logNumber) {
            this.loadLinks();
        }
    };

    loadLinks = () => {
        this.setState(() => ({
            loading: true
        }));

        WSLinks.getLinks(this.props.log?.code || this.props.logNumber)
            .then(response => {
                if(response.body.data.length > 0) {
                    const {name, panels } = this.props;
                    panels[name] = true;
                }
                this.setState(() => ({
                    links: response.body.data,
                    loading: false
                }));
            }).catch(error => {
            this.props.handleError(error);
            this.setState(() => ({
                loading: false
            }));
        })
    };

    updateProperty = (key, value) => {
        this.setState(() => ({
            [key]: value
        }));
    };

    handleLinkClick = (link) => {
        window.open(link.url, '_blank')
    };

    getIcon = link => {
        switch (link.system) {
            case "WEBPAGE": {
                return "web"
            }
            case "JIRA" : {
                return "check_box"
            }
            case CI_INSTRUM_LOGBOOK : {
                return "book"
            }
            default: {
                return "dehaze"
            }
        }
    };

    onPlusClick = () => {
        this.setState(() => ({
            dialogOpen: true,
            editing: false,
            system: null,
            linkURI: null,
            description: null
        }));
    };

    calculateURL = (system, linkURI) => {
        return [system].map(elem => {
            if (elem === "AFT") {
                return AFT_LINK
            } else if (elem === "JIRA") {
                return JIRA_LINK
            } else if (elem === "SNOW") {
                return SNOW_LINK
            } else if (elem === "IMPACT") {
                return IMPACT_LINK
            } else if (elem === CI_INSTRUM_LOGBOOK) {
                return CI_INSTR_LINK
            } else {
                return ""
            }
        })[0] + linkURI;
    };

    saveLink = () => {
        if (!this.state.editing) {
            return WSLinks.addLink({
                logID: this.props.log?.code || this.props.logNumber,
                system: this.state.system,
                description: this.state.description,
                url: this.calculateURL(this.state.system, this.state.linkURI),
                sysRecordID: this.state.linkURI
            }).then(() => {
                this.setState(() => ({
                    dialogOpen: false
                }));

                this.loadLinks();
            })
        } else {
            return WSLinks.updateLink({
                logID: this.props.log?.code || this.props.logNumber,
                system: this.state.system,
                description: this.state.description,
                url: this.calculateURL(this.state.system, this.state.linkURI),
                sysRecordID: this.state.linkURI,
                logbookLinkPK: this.state.linkEditedPK
            }).then(() => {
                this.setState(() => ({
                    dialogOpen: false,
                    linkEditedPK: null
                }));

                this.loadLinks();
            })
        }
    };

    cancel = () => {
        this.setState(() => ({
            dialogOpen: false
        }));
    };

    edit = (link, e) => {
        this.stopPropagation(e);
        this.setState(() => ({
            system: link.system,
            linkURI: link.sysRecordID,
            description: link.description,
            linkEditedPK: link.logbookLinkPK,
            dialogOpen: true,
            editing: true
        }));
    };

    delete = (link, e) => {
        this.stopPropagation(e);

        this.props.openConfirmDialog({
            message: "Are you sure you want to delete this link?",
            waitForCompletion: true
        }, () => {
            WSLinks.deleteLink(link.logbookLinkPK)
                .then(() => {
                    this.loadLinks();
                }).catch(error => {
                    this.props.handleError(error);
            })
        })
    };

    stopPropagation = e => {
        e.cancelBubble = true;
        if (e.stopPropagation) {
            e.stopPropagation();
        }
    };

    onMouseOver = link => {
        this.setState(() => ({
            linkHovered: link
        }));
    };

    onMouseOut = event => {
        this.setState(() => ({
            linkHovered: null
        }));
    };

    render() {
        const { classes, readonly } = this.props;

        return (
            <div style={{padding: '16px'}}>
                <Grid container spacing={8}>
                    {this.state.links.map( (link, index) => (
                        <Grid key={index} item xs={12} lg={12} onClick={() => this.handleLinkClick(link)}>
                            <div className={classes.link}
                                 onMouseOver={() => this.onMouseOver(link)}
                                 onMouseLeave={this.onMouseOut} >
                                <div className={classes.insideLinkDiv}>
                                    <Icon style={{marginBottom: 5}}>{this.getIcon(link)}</Icon>
                                    <div style={{fontWeight: 500, marginBottom: 5}}>{link.system}</div>
                                    <div style={{fontWeight: 200, marginBottom: 5}}>{link.description}</div>
                                </div>
                                <div className={classes.insideLinkDiv} style={{width: "60%", maxWidth: 250}} >
                                    <span style={{fontWeight: 500}}>{link.sysRecordID}</span>
                                    {this.state.linkHovered === link && (
                                        <div className={classes.icons} >
                                            <Edit style={{fontSize: 30}} onClick={(e) => this.edit(link, e)}/>
                                            <Delete style={{fontSize: 30}} onClick={(e) => this.delete(link, e)}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Divider/>
                        </Grid>
                    ))}
                    {readonly ? null : <Button style={{cursor: "pointer"}} onClick={this.onPlusClick}>
                        <Add />
                        Add link
                    </Button>}
                </Grid>
                <AddLinkDialog
                    open={this.state.dialogOpen}
                    saveLink={this.saveLink}
                    cancel={this.cancel}
                    updateProperty={this.updateProperty}
                    system={this.state.system}
                    linkURI={this.state.linkURI}
                    description={this.state.description}
                    editing={this.state.editing}
                />
            </div>
        );
    }
}

export default withStyles(styles)(Links);