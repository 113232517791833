// To be removed once we use the opensource version of eam-components

import { createTheme } from '@mui/material/styles';
import { blue, green, red, lightBlue, lightGreen, deepOrange } from '@mui/material/colors';

const muiSettings = ({ primaryColor, secondaryColor, primaryExtraLight }) => ({
    palette: {
        primary: {
            main: primaryColor,
            extraLight: primaryExtraLight
        },
        secondary: {
            main: secondaryColor
        },
        info: {
            main: '#e0e0e0'
        }
    },
    typography: {
        fontFamily: 'Roboto'
        // body1: {
        //     fontSize: '12px',
        // }
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    maxWidth: '100%',
                    boxSizing: 'border-box',
                    margin: '0',
                    '&.MuiGrid-item': {
                        boxSizing: 'border-box',
                        margin: '0',
                        padding: '4px 0 0 4px',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginTop: '0px',
                    marginBottom: '0px',
                    overflow: 'visible',
                },
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    fontSize: '18px',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                }
            },
        },
        MuiTableCell: {
            styleOverrides: {
                // root: {
                //     fontSize: '14px'
                // },
                // head: {
                //     fontSize: '14px'
                // },
            },
        },
        MuiExpansionPanel: {
            styleOverrides: {
                "root": {
                    "&.Mui-expanded": {
                      "margin": "8px 0"
                    }
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    whiteSpace: 'normal',
                    lineHeight: '0.9em',
                    paddingTop: 5,
                    paddingBottom: 5,
                    height: 'inherit'
                },
            },
        }
    },
});

const BLUE = createTheme(muiSettings({ primaryColor: blue[500], secondaryColor: lightBlue[900] }));
const GREEN = createTheme(muiSettings({ primaryColor: green[600], secondaryColor: lightGreen[900] }));
const RED = createTheme(muiSettings({ primaryColor: red[500], secondaryColor: deepOrange[900] }));
const DANGER = createTheme(muiSettings({ primaryColor: red[900], secondaryColor: red[500] }));

export default {
    BLUE,
    GREEN,
    RED,
    DANGER
}