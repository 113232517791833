export const LOG_WRAPPER_DTO = {
    EAM_CASE: 'eamCaseManagement',
    EVENT_DETATILS: 'eventDetails',
}

export const LOG_DTO2 = {
    CODE: 'caseCode',
    DESCRIPTION: 'caseDescription',
    CLASSCODE: 'classCode',
    CLASSDESC: 'classDesc',
    EQUIPMENTCODE: 'equipmentCode',
    EQUIPMENTDESC: 'equipmentDesc',
    TYPECODE: 'caseType',
    TYPEDESC: 'typeDesc',
    DEPARTMENTCODE: 'departmentCode',
    DEPARTMENTDESC: 'departmentDesc',
    STATUSCODE: 'statusCode',
    STATUSDESC: 'statusDesc',
    SYSTEMSTATUSCODE: 'systemStatusCode',
    LOCATIONCODE: 'locationCode',
    LOCATIONDESC: 'locationDesc',
    WORKADDRESS: 'workaddress',
    RESPONSIBLECODE: 'responsibleCode',
    RESPONSIBLEDESC: 'responsibleDesc',
    RESPONSIBLEEMAIL: 'responsibleEMail',
    ASSIGNEDTOCODE: 'assignedToCode',
    ASSIGNEDTODESC: 'assignedToDesc',
    ASSIGNEDTOEMAIL: 'assignedToEMail',
    CREATEDBY: 'createdBy',
    CREATEDBYNAME: 'createdByName',
    UPDATEDBY: 'updatedBy',
    UPDATEDBYNAME: 'updatedByName',
    PRIORITY: 'priority',
    SCHEDULEDSTARTDATE: 'scheduledStartDate',
    SCHEDULEDENDDATE: 'scheduledEndDate',
    REQUESTEDSTARTDATE: 'requestedStartDate',
    REQUESTEDENDDATE: 'requestedEndDate',
    STARTDATE: 'startDate',
    COMPLETEDDATE: 'completedDate',
    CREATEDATE: 'createDate',
    UPDATEDDATE: 'updatedDate',
    DATEREQUESTED: 'daterequested',
    EVENTSTARTDATE: 'eventstartdate',
    EVENTENDDATE: 'eventenddate',
    CUSTOM_FIELDS: 'customFields',
    CUSTOM_FIELDS_MAP: 'customFieldMap',
    USERDEFINEDFIELDS: 'userDefinedFields',
    NEEDSDONE: 'needsDone',
    NEEDSUNDONE: 'needsUndone',
    NEEDS: 'needs',
    LINKS: 'links',
    COMMENTS: 'comments',
    TASKS: 'tasks',
    UNCOMPLETEDTASKS: 'uncompletedTasks',
    COMPLETEDTASKS: 'completedTasks',
    INTSTUDYNEEDDETAILS: 'intStudyNeedDetails',
    INTSTUDYNEEDREQFLAG: 'intStudyNeedReqFlag',
    DOCECRNEEDDETAILS: 'docecrNeedDetails',
    DOCECRNEEDREQFLAG: 'docecrNeedReqFlag',
    DOCSRRNEEDDETAILS: 'docsrrNeedDetails',
    DOCSRRNEEDREQFLAG: 'docsrrNeedReqFlag',
    SERVICECODE: 'serviceCode',
    AREA: 'area',
    COST_CODE: 'costCode',
    PROBLEMDESC: 'problemdesc',
}

export const LOG_DTO = {
    CODE: 'caseCode',
    DESCRIPTION: 'caseDescription',
    EQUIPMENTCODE: 'equipmentCode',
    CASETYPE: 'caseType',
    DEPARTMENTCODE: 'departmentCode',
    STATUSCODE: 'statusCode',
    SYSTEMSTATUSCODE: 'systemStatusCode',
    REVIEWEDBYDESIGN: 'reviewedByDesign',
    DATEREVIEWED: 'dateReviewed',
    CREATEDBY: 'createdBy',
    CREATEDDATE: 'createdDate',
    UPDATEDBY: 'updatedby',
    DATEUPDATED: 'dateUpdated',
    CLASSCODE: 'classCode',
    LOCATIONCODE: 'locationCode',
    SERVICEPROBLEMCODE: 'serviceProblemCode',
    AREA: 'area',
    WORKADDRESS: 'workaddress',
    CASEPRIORITYCODE: 'casePriorityCode',
    CASEPRIORITYENTITY: 'casePriorityEntity',
    COSTCODE: 'costCode',
    EVENTSTARTDATE: 'eventStartDate',
    EVENTENDDATE: 'eventEndDate',
    REGULATORY: 'regulatory',
    FOLLOWUPREQUIRED: 'followUpRequired',
    HAZARDOUSMATERIAL: 'hazardousMaterial',
    ESTIMATEDTOTALCOST: 'estimatedTotalCost',
    TOTALCOST: 'totalCost',
    CURRENCYCODE: 'currencyCode',
    COSTREFRESHREQUIRED: 'costRefreshRequired',
    CASEPARENTCODE: 'caseParentCode',
    WORKORDERCODE: 'workOrderCode',
    PERMITTOWORKCODE: 'permitToWorkCode',
    SHIFTCODE: 'shiftCode',
    PROJECTCODE: 'projectCode',
    CAMPAIGNCODE: 'campaignCode',
    LINEARREFUOM: 'linearRefUom',
    LINEARREFERENCEEVENT: 'linearreferenceevent',
    INSPECTIONDIRECTIONCODE: 'inspectionDirectionCode',
    FLOWCODE: 'flowCode',
    STARTINGAT: 'startingAt',
    EQUIPMENTLENGTH: 'equipmentLength',
    REQUESTEDBY: 'requestedBy',
    DATEREQUESTED: 'dateRequested',
    PERSONRESPONSIBLE: 'personResponsible',
    EMAIL: 'email',
    PREPAREDBY: 'preparedBy',
    PREPAREDBYEMAIL: 'preparedByEmail',
    ASSIGNEDTO: 'assignedTo',
    ASSIGNEDTOEMAIL: 'assignedToEmail',
    SCHEDULEDSTARTDATE: 'scheduledStartDate',
    SCHEDULEDENDDATE: 'scheduledEndDate',
    REQUESTEDSTART: 'requestedStart',
    REQUESTEDEND: 'requestedEnd',
    STARTDATE: 'startDate',
    COMPLETEDDATE: 'completedDate',
    CONTACTRECORDCODE: 'contactRecordCode',
    CONTACTRECORDSTATUS: 'contactRecordStatus',
    SOURCETYPE: 'sourceType',
    WODESCRIPTION: 'woDescription',
    STANDARDWO: 'standardWo',
    WORKORDERTYPE: 'workOrderType',
    WORKORDERCLASSCODE: 'workorderClassCode',
    WORKORDERSTATUS: 'workorderStatus',
    WOPRIORITY: 'woPriority',
    TASKCODE: 'taskCode',
    CASETASKJOBPLAN: 'casetaskJobPlan',
    TRADECODE: 'tradeCode',
    ESTIMATEDHOURS: 'estimatedHours',
    PERSONS: 'persons',
    FOLLOWUPWORKORDER: 'followupWorkOrder',
    HASDEPARTMENTSECURITY: 'hasDepartmentSecurity',
    ISENHANCEDPLANNINGTASK: 'isEnhancedPlanningTask',
    ISCASEHAVETASKS: 'isCaseHaveTasks',
    PROBLEMDESC: 'problemdesc',
}

// Maps the CSCASE ScreenLayout element Id to the eam-wshub-core EAMCaseManagement DTO
export const LOG_CASE_MAP = {
    "casecode": "caseCode",
    "casedescription": "caseDescription",
    "casetype": "caseType",
    "createdby": "createdBy",
    "datecreated": "createdDate",
    "area": "area",
    "campaign": "campaignCode",
    "class": "classCode",
    "followuprequired": "followUpRequired",
    "parent": "caseParentCode",
    "priority": "casePriorityCode",
    "costcode": "costCode",
    "refreshcalculatedcosts": "costRefreshRequired",
    "totalestimatedcosts_curr": "currencyCode",
    "totalcalculatedcosts_curr": "currencyCode",
    "totalestimatedcosts": "estimatedTotalCost",
    "eventenddate": "eventEndDate",
    "eventstartdate": "eventStartDate",
    //"hazardousmaterial": "isHazardousMaterial",
    "location": "locationCode",
    "permittowork": "permitToWorkCode",
    "project": "projectCode",
    "regulatory": "regulatory",
    "servicecode": "serviceProblemCode",
    "shift": "shiftCode",
    "totalcalculatedcosts": "totalCost",
    "workaddress": "workaddress",
    "workorder": "workOrderCode",
    "esignaturedate": "dateReviewed",
    "dateupdated": "dateUpdated",
    "department": "departmentCode",
    "equipment": "equipmentCode",
    "jobplan": "casetaskJobPlan",
    "esthours": "estimatedHours",
    "followupwo": "followupWorkOrder",
    "pplreq": "persons",
    "standardwo": "standardWo",
    "taskplan": "taskCode",
    "trade": "tradeCode",
    "wodescription": "woDescription",
    "wopriority": "woPriority",
    "woclass": "workorderClassCode",
    "wostatus": "workorderStatus",
    "wotype": "workOrderType",
    "flow": "flowCode",
    "inspectiondirection": "inspectionDirectionCode",
    "topointuom": "linearRefUom",
    "frompointuom": "linearRefUom",
    "rstatus": "systemStatusCode",
    "status": "statusCode",
    "assignedto": "assignedTo",
    "assignedtoemail": "assignedToEmail",
    "completed": "completedDate",
    "incidentrequest": "contactRecordCode",
    "incidentrequeststatus": "contactRecordStatus",
    "daterequested": "dateRequested",
    "responsibleemail": "email",
    "responsible": "personResponsible",
    "preparedby": "preparedBy",
    "preparedbyemail": "preparedByEmail",
    "requestedby": "requestedBy",
    "reqenddate": "requestedEnd",
    "reqstartdate": "requestedStart",
    "schedenddate": "scheduledEndDate",
    "schedstartdate": "scheduledStartDate",
    "sourcetype": "sourceType",
    "startdate": "startDate",
    "updatedby": "updatedby",
    "problemdesc": "problemdescription"
  }


// Maps the WSJOBS ScreenLayout element Id to the eam-wshub-core WorkOrder DTO
export const FOLLOWUPWO_WO_MAP = {
    "actioncode": "actionCode",
    "assignedto": "assignedTo",
    "causecode": "causeCode",
    "woclass": "classCode",
    "woclassorganization": "classOrganization",
    "datecompleted": "completedDate",
    "costcode": "costCode",
    "createdby": "createdBy",
    "datetimecreated": "createdDate",
    "datecreated": "createdDate",
    "department": "departmentCode",
    "dependant": "depend",
    "downtimehours": "downtimeHours",
    "equipment": "equipmentCode",
    "equipmentorg": "equipmentOrganization",
    "evtisstype": "issType",
    "failurecode": "failureCode",
    "location": "locationCode",
    "locationorg": "locationOrganization",
    "parentwo": "parentWO",
    "priority": "priorityCode",
    "problemcode": "problemCode",
    "projbud": "projectBudget",
    "project": "projectCode",
    "datereported": "reportedDate",
    "reportedby": "reportedBy",
    "reqenddate": "requestedEndDate",
    "reqstartdate": "requestedStartDate",
    "routecode": "route",
    "routerevision": "routeRevision",
    "schedenddate": "scheduledEndDate",
    "schedgroup": "assignedBy",
    "standardwo": "standardWO",
    "startdate": "startDate",
    "workorderstatus": "statusCode",
    "schedstartdate": "scheduledStartDate",
    "targetvalue": "targetValue",
    "workordertype": "typeCode",
    "vendor": "vendor",
    "warranty": "warranty",
    "description": "description",
    "workordernum": "number",
    "organization": "organization",
}
