import WS from './WS';

class WSCase {

    getCase(screenCode, caseCode, config = {}) {
        return WS._get(`/case/${screenCode}/${caseCode}`, config);
    }

    updateCase(theCase, config = {}) {
        return WS._put(`/case`, theCase, config);
    }

    initCase(screenCode, config = {}) {
        return WS._get(`/case/init/${screenCode}`, config);
    }

    createCase(theCase, config = {}) {
        return WS._post(`/case`, theCase, config);
    }

    deleteCase(caseCode, config = {}) {
        return WS._delete(`/case/${caseCode}`, config);
    }

    postNotification(notification, config = {})  {
        return WS._post(`/watchers/notifications`, notification, config);
    }

    getCaseSummary = caseCode => {
        return WS._get(`/case/summaryinfo/${caseCode}`);
    }
}

export default new WSCase();