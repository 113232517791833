import { combineReducers } from 'redux';
import application from './applicationReducers';
import layout from './layoutReducer';
import ui from 'react-eis-components/reducers/uiReducers';

export default combineReducers({
    application,
    layout,
    ui
})
