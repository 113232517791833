export const UPDATE_LAYOUT = 'UPDATE_LAYOUT';
export const SET_PAGE = 'SET_PAGE';

export function updateLayout(value) {
    return {
        type: UPDATE_LAYOUT,
        value: value
    }
}

export function setPage(masterPage, page = null) {
    if (page) {
        return {
            type: SET_PAGE,
            masterPage,
            page
        }
    }
    else {
        return {
            type: SET_PAGE,
            masterPage,
            page: masterPage
        }
    }
}
