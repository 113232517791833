import queryString from 'query-string';
import WS from "../tools/rest/WS";

export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const SET_LOGBOOK = "SET_LOGBOOK";
export const SET_SKIPTRANSLATIONS = "SET_SKIPTRANSLATIONS";

export function updateApplication(value) {
    return {
        type: UPDATE_APPLICATION,
        value: value,
    };
}

export function getInitData() {
    return (dispatch) => {
        Promise.all([WS.getUserData(), WS.getApplicationData(), WS.getLogbookConfiguration()])
            .then((values) => {
                dispatch(
                    updateApplication({
                        userData: values[0].body.data,
                        applicationData: values[1].body.data,
                        logbooks: values[2].map(conf => {
                            let cfg = {};
                            try {
                                cfg = JSON.parse(conf?.cfg || "{}");
                            } catch (err) {
                                console.error(err);
                            }

                            return {
                                ...conf,
                                cfg,
                            }
                        }),
                        skipTranslations: queryString.parse(location.search, { parseBooleans: true })?.lang === 'DEV',
                    })
                );
            })
            .catch((response) => {
                dispatch(
                    updateApplication({ userData: { invalidAccount: true } })
                );
            });
    };
}

export function setLogbook(screenCode) {
    return {
        type: SET_LOGBOOK,
        value: screenCode,
    };
}

export function setSkipTranslations(value) {
    return {
        type: SET_SKIPTRANSLATIONS,
        value,
    };
}

