import Grid from '@mui/material/Grid';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import useFieldsValidator from 'eam-components/dist/ui/components/inputs-ng/hooks/useFieldsValidator';
import { LOG_CASE_MAP, LOG_DTO2 } from 'enums/LogDTO';
import { useEffect, useMemo, useState } from 'react';
import WSPosition from "tools/rest/WSPosition";
import { EAMInputField } from './ScreenEAMBlock';
import { DEPARTMENT } from '../LogDetails/panels/followup/CreateWODialog';


export const SITE = "udfchar20";
export const SUBSYSTEM = "udfchar21";
export const EQCLASS = "udfchar22";
export const EQOTHERINFO = "udfchar29";
export const EQUIPMENT = "equipment";

const SITE_CLASS = 'Q1';
const SUBSYSTEM_CLASS_LIST = ['Q2', 'Q3'];
const EQ_CLASS_LIST = ['Q4', 'QI', 'QY'];


const loadSiteValues = async (departmentCode) =>
    (await WSPosition.getSiteValues(departmentCode, SITE_CLASS))?.body.data;

const loadSubsystemValues = async (siteValue) =>
    (await WSPosition.getSubsystemsValues(siteValue, ...SUBSYSTEM_CLASS_LIST))?.body.data;

const loadEqClassValues = async (subsystemValue) =>
    (await WSPosition.getEqClassValues(subsystemValue, ...EQ_CLASS_LIST))?.body.data;

const loadAndSet = async (promiseCall, setState) => {
    const a = await promiseCall;
    setState(a);
}

const CRYOPositionCapture = (props) => {
    const { log = {}, updateObj, logbookConf, logbookCode, errorMessages, readonly, skipTranslations } = props;

    const [siteValues, setSiteValues] = useState([]);
    const [subsystemValues, setSubsystemValues] = useState([]);
    const [eqClassValues, setEqClassValues] = useState([]);

    const department = log?.[LOG_DTO2.DEPARTMENTCODE];
    const site = log.userDefinedFields?.[SITE];
    const subsystem = log.userDefinedFields?.[SUBSYSTEM];
    const eqClass = log.userDefinedFields?.[EQCLASS];

    useEffect(() => {
        loadAndSet(loadSiteValues(log[LOG_DTO2.DEPARTMENTCODE]), setSiteValues);
        loadAndSet(loadSubsystemValues(log.userDefinedFields?.[SITE]), setSubsystemValues);
        loadAndSet(loadEqClassValues(log.userDefinedFields?.[SUBSYSTEM]), setEqClassValues);
    }, [department, site, subsystem]);

    const prepareAutocompleteInputEquipment = async (dep, subs, eqClass, hint, config) => WSPosition.autocompleteEquipment(hint, logbookConf?.depRegex, subs, eqClass, config);

    const onEquipmentChange = (_, other, opt) => {
        updateObj({[LOG_DTO2.EQUIPMENTCODE]: opt?.code ?? ''});
        // if site is empty, then populate it with info from the Equipment
        if (!opt?.desc) {
            return;
        }

        // if subsystem is empty, then populate it with info from the Equipment
        if (!site) {
            WSPosition.getRootparent(opt.code).then(resp => updateObj({[SITE]: resp.body.data}, ['userDefinedFields']));
        }

        // if subsystem is empty, then populate it with info from the Equipment
        if (!subsystem) {
            updateObj({[SUBSYSTEM]: opt?.parent || ''}, ['userDefinedFields'])
        }

        // if class is empty, then populate it with info from the Equipment
        if (!eqClass) {
            updateObj({[EQCLASS]: opt?.objClass || ''}, ['userDefinedFields'])
        }
    };

    const layout = useMemo(() => Object.values(logbookConf?.caseScreenFields).flat()
        .reduce((acc, el) => ({...acc, [el.elementId]: el}), {}), [logbookCode]);

    const elProps = { readonly, customValues: [], mapDto: LOG_CASE_MAP, values: [], updateF: updateObj, obj: log, errorMessages, master: 'CSCASE', rentity: 'CASE', skipTranslations };

    return <Grid container style={{ padding: '12px'}}>
            <EAMInputField {...elProps} options={siteValues || []} elementInfo={{...layout[SITE], attribute: 'O'}} />
            <EAMInputField {...elProps} options={subsystemValues || []} elementInfo={{...layout[SUBSYSTEM], attribute: 'O'}} />
            <Grid item lg={6} md={12} style={{ padding: 0, margin: 0}}>
                <EAMInputField {...elProps}
                    autocompleteHandler={prepareAutocompleteInputEquipment}
                    autocompleteHandlerParams={[department, subsystem, eqClass]}
                    elementInfo={{...layout[EQUIPMENT], attribute: 'O'}}
                    updateF={onEquipmentChange}
                />
            </Grid>
            <Grid item lg={6} md={12} style={{ padding: 0, paddingLeft: '4px', margin: 0}}>
                <EAMInputField {...elProps} options={eqClassValues || []} elementInfo={{...layout[EQCLASS], attribute: 'O'}} />
            </Grid>
            {/* <EAMInputField {...elProps} elementInfo={{...layout[EQOTHERINFO], attribute: 'O'}} /> */}
        </Grid>
}

export default CRYOPositionCapture;