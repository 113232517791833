
import React from 'react';
import BundledTinyEditor from './BundledTinyEditor';


const EventDetailTiny = ({ data, onChange, isMaximized, maxHeight, readonly }) =>
        <BundledTinyEditor
            maxHeight={maxHeight}
            value={data}
            onEditorChange={onChange}
            disabled={readonly}
        />

export default EventDetailTiny;
