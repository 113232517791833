import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EAMSelect from 'eam-components/dist/ui/components/inputs-ng/EAMSelect';
import EAMTextField from 'eam-components/dist/ui/components/inputs-ng/EAMTextField';
import React from 'react';
import BlockUi from 'react-block-ui';
import { CI_INSTRUM_LOGBOOK } from './Links';

class AddLinkDialog extends React.Component {

    state = {
        loading: false
    };

    componentDidMount = () => {
        this.children = {}
    };

    saveLink = () => {
        const validated = this.validateFields(this.children);

        if (!validated) {
            return;
        }

        this.setState(() => ({
            loading: true
        }), () => {
            this.props.saveLink()
                .then(() => {
                    this.setState(() => ({
                        loading: false
                    }));
                });
        });
    };

    validateFields = children => {
        let validationPassed = true;
        Object.keys(children).forEach(key => {
            if (!children[key].validate()) {
                validationPassed = false
            }
        });
        return validationPassed
    };

    getLinkURILabel = system => {
        switch (system) {
            case "WEBPAGE": {
                return "URL"
            }
            case "JIRA": {
                return "JIRA ID"
            }
            case "SNOW": {
                return "Ticket number"
            }
            case "IMPACT": {
                return "IMPACT Activity"
            }
            default: {
                return "Record ID"
            }
        }
    };

    render() {
        const { open, cancel, system, linkURI, description } = this.props;

        const systemValues = ["JIRA", "AFT", "SNOW", "IMPACT"].map(elem => ({code: elem, desc: elem})).concat({
            code: "WEBPAGE",
            desc: "Web Page"
        }).concat({
            code: CI_INSTRUM_LOGBOOK,
            desc: "CI Instrumentation"
        });

        return (
            <Dialog
                open={open}
                disableBackdropClick
                fullWidth
            >
                <DialogTitle>Add link</DialogTitle>
                <BlockUi tag="div" blocking={this.state.loading} style={{height: "100%", width: "100%"}}>
                    <DialogContent>
                        <EAMSelect
                            onChange={(val) => this.props.updateProperty("system", val?.code)}
                            value={system}
                            valueKey="system"
                            options={systemValues}
                            elementInfo={{
                                attribute: 'R',
                                xpath: 'system',
                                readonly: this.props.editing
                            }}
                            label='System'
                            children={this.children}
                        />
                        <EAMTextField
                            onChange={(val) => this.props.updateProperty("linkURI", val)}
                            value={linkURI}
                            valueKey="linkURI"
                            elementInfo={{
                                attribute: 'R',
                                xpath: 'linkURI'
                            }}
                            label={this.getLinkURILabel(system)}
                            children={this.children}
                        />
                        <EAMTextField
                            onChange={(val) => this.props.updateProperty("description", val)}
                            value={description}
                            valueKey="description"
                            elementInfo={{
                                attribute: 'O',
                                xpath: 'description'
                            }}
                            label='Description'
                            children={this.children}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.saveLink} color="primary">
                            {this.props.editing ? "Save" : "Add"}
                        </Button>
                    </DialogActions>
                </BlockUi>
            </Dialog>
        )
            ;
    }
}

export default AddLinkDialog;