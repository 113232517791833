import WS from './WS';
import queryString from 'query-string';

const readCases = ({ hint, logbookCode }, config = {}) => WS._get(`/v2/logs?${queryString.stringify({ hint, logbookCode })}`, config);

const createCase = (caseMgmt, config = {}) => WS.__post(`/v2/logs`, caseMgmt, config);

const readCase = (caseCode, config = {}) => WS.__get(`/v2/logs/${caseCode}`, config);

const updateCase = (caseCode, caseMgmt, config = {}) => WS.__put(`/v2/logs/${caseCode}`, caseMgmt, config);

const deleteCase = (caseCode, config = {}) => WS.__delete(`/v2/logs/${caseCode}`, config);

const readUdfCodeDescLOV = (rentity, fieldId, config = {}) => WS._get(`/v2/codedesc/${rentity}/${fieldId}`, config);

const readUdfCodeLOV = (rentity, fieldId, config = {}) => WS._get(`/v2/code/${rentity}/${fieldId}`, config);

const readGenericLOV = (genericLov, config = {}) => WS._post(`/v2/lov`, genericLov, config);

const readStatusList = ({rentity, oldStatus}, config = {}) => WS._get(`/v2/entities/${rentity}/status?oldStatus=${oldStatus ? encodeURIComponent(oldStatus) : ""}`, config);

const readEquipment = ({ equipmentCode }, config = {}) => WS.__get(`/v2/equipment?equipmentCode=${equipmentCode ? encodeURIComponent(equipmentCode) : ""}`, config);

export default {
    readCases,
    createCase,
    readCase,
    updateCase,
    deleteCase,
    readUdfCodeDescLOV,
    readUdfCodeLOV,
    readGenericLOV,
    readStatusList,
    readEquipment,
};
