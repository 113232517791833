import { handleError, openConfirmDialog, showError, showSuccess, showWarning } from 'react-eis-components/actions/uiActions';
import { connect } from 'react-redux';
import { setLogbook, setSkipTranslations } from "../../actions/applicationActions";
import LogbookPage from "./LogbookPage";

const mapStateToProps = (state, ownProps) => {
    return {
        logbookCode: ownProps.match.params.screenCode,
        logbookDesc: state.application.logbooks.find(conf => conf.logbook === ownProps.match.params.screenCode)?.description,
        logbooks: state.application.logbooks,
        userData: state.application.userData,
        applicationData: state.application.applicationData,
        skipTranslations: state.application.skipTranslations,
    }
};

const LogbookPageContainer = connect(
    mapStateToProps, {
        setLogbook,
        handleError,
        showSuccess,
        showError,
        openConfirmDialog,
        showWarning,
        setSkipTranslations,
    }
)(LogbookPage);

export default LogbookPageContainer;
