import CKEditor from "ckeditor4-react";
import Panel from 'ui/components/Panel';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
    },
}));

const adaptChange = (onChange) => (evt) => {
    const realvalue = String(evt.editor.getData());
    onChange(realvalue);
};

const EventDetail = ({ data, onChange, defaultExpanded }) => {
    const classes = useStyles();

    return <Panel
                heading="Event Detail Capture"
                defaultExpanded={defaultExpanded}
                style={{padding: 0}}
                classes={{
                    expanded: classes.root,
                }}
            >
                <CKEditor
                    data={data}
                    onChange={adaptChange(onChange)}
                />
            </Panel>
}

export default EventDetail;
