import { connect } from 'react-redux'
import LogbookSelection from './LogbookSelection'
import { setLogbook } from "../../../actions/applicationActions";

const mapStateToProps = (state, ownProps) => {
    return {
        screens: state.application.userData.screens,
        logbooks: state.application.logbooks,
    }
};

const LogbookSelectionContainer = connect(
    mapStateToProps, {
        setLogbook
    }
)(LogbookSelection);

export default LogbookSelectionContainer;