import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/fr-fr';

import { Editor } from '@toast-ui/react-editor';
import React from 'react';
import Panel from 'ui/components/Panel';

const editorRef = React.createRef();

const adaptChange = (onChange) => () => onChange(String(editorRef.current.editorInst.getMarkdown()));

const EventDetail = ({ data, onChange, defaultExpanded }) => {

    return (
        <Panel
            heading="Event Detail Capture"
            defaultExpanded={defaultExpanded}
            detailsStyle={{
                boxSizing: 'border-box',
                margin: 0,
                padding: 0,
                height: '100%',
            }}
        >
            <div style={{
                overflow: 'auto',
                resize: 'vertical',
                minHeight: '300px',
                height: '300px',
            }}>
                <Editor
                    initialValue={data || ""}
                    ref={editorRef}
                    onChange={adaptChange(onChange)}
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                    hideModeSwitch="true"
                    language="fr"
                    minHeight='300px'
                    height='100%'
                />
            </div>
        </Panel>
    );
}

export default EventDetail;
