import { Button, Chip, Grid } from '@mui/material';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs-ng/EAMAutocomplete';
import React from 'react';
import BlockUi from 'react-block-ui';
import Panel from 'ui/components/Panel';
import WS from '../../../../tools/rest/WSWatchers';
import Tools from '../../../../tools/Tools';

class Watchers extends React.Component {

    state = {
        watchers: [],
        watcherCode: '',
    };

    componentDidMount = () => {
        this.loadWatchers();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.log.code !== this.props.log.code || this.props.logNumber !== prevProps.logNumber) {
            this.loadWatchers();
        }
    };

    loadWatchers = () => {
        this.setState(() => ({
            loading: true
        }));

        WS.getWatchers('*', this.props.logNumber || this.props.log.code)
            .then(response => {
                this.setState(() => ({
                    watchers: response.body.data,
                    loading: false
                }));
            }).catch(error => {
            this.props.handleError(error);
        })
    };

    updateProperty = (key, value) => {
        this.setState(() => ({
            [key]: value
        }));
    };

    prepareInputForAutocomplete = (hint, config) => {
        return WS.autocompleteWatcher(hint, config)
            .then(response => ({
                body: {
                    data: response.body.data.map(element => ({code: element.email, desc: element.name}))
                }
            }))
    };

    addWatcher = () => {
        if (this.state.watcherCode) {
            if (!this.state.watcherDesc) {
                let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let isValid = regex.test(this.state.watcherCode.toLowerCase());

                if (!isValid) {
                    this.props.showWarning("Email address is invalid.");
                    return;
                }
            }

            WS.addWatcher({
                email: this.state.watcherCode,
                screen: '*',
                code: this.props.logNumber || this.props.log.code
            }).then(response => {
                this.setState(() => ({
                    watchers: response.body.data,
                    loading: false,
                    watcherCode: null,
                    watcherDesc: null
                }));
            }).catch(error => {
                this.props.handleError(error);
            })
        }
    };

    handleDelete = watcher => {
        this.setState(() => ({
            loading: true
        }));

        WS.deleteWatcher(watcher)
            .then(response => {
                this.setState(() => ({
                    watchers: response.body.data,
                    loading: false
                }));
            }).catch(error => {
            this.props.handleError(error);
        })
    };

    startWatching = () => {
        this.setState(() => ({
            loading: true
        }));

        WS.addWatcher({
            email: this.props.userData.eamAccount.emailAddress,
            screen: '*',
            code: this.props.logNumber || this.props.log.code
        }).then(response => {
            this.setState(() => ({
                watchers: response.body.data,
                loading: false
            }));
        }).catch(error => {
            this.props.handleError(error);
        })
    };

    render() {
        const { name, panels, onPanelChange, readonly } = this.props;

        const userCanModify = Tools.userCanModifyLog(this.props.log, this.props.userData.eamAccount);

        const showStartWatchingButton = !userCanModify && this.state.watchers.filter(watcher => watcher.username === this.props.userData.eamAccount.userCode).length === 0;

        return (
            <BlockUi tag="div" blocking={this.state.loading} style={{height: "100%", width: "100%", padding: '8px 16px 16px'}}>
                <Grid container spacing={8}>
                {readonly ? null : <><Grid item xl={8} xs={8}>
                        <EAMAutocomplete
                            label={"Watcher"}
                            autocompleteHandler={this.prepareInputForAutocomplete}
                            elementInfo={{
                                attribute: 'O',
                                xpath: "watcher",
                            }}
                            hideDescription
                            value={{ code: this.state.watcherCode || '' }}
                            onChange={(opt) => this.setState((prevState) => ({ watcherCode: opt?.desc ? opt.code : opt?.code ? prevState.watcherCode : '' }))}
                            valueKey="parentCode" />
                    </Grid><Grid item xl={4} xs={4}>
                            <Button onClick={this.addWatcher}
                                disabled={!this.state.watcherCode}
                                style={{ width: "100%", height: "100%", fontSize: 16 }}>
                                Add watcher
                            </Button>
                        </Grid></>}

                    {showStartWatchingButton && (
                        <Grid item xs={12} lg={6}>
                            <Button onClick={this.startWatching}
                                    style={{width: "100%", height: "100%", fontSize: 16}}>
                                Start Watching
                            </Button>
                        </Grid>
                    )}
                    <Grid item xl={12} xs={12}>
                        {this.state.watchers.map((watcher, index) => {
                            return (
                                <Chip
                                    key={index}
                                    label={watcher.email}
                                    onDelete={!readonly || watcher.username === this.props.userData.eamAccount.userCode ? (event) => this.handleDelete(watcher) : undefined}
                                />
                            )
                        })}
                    </Grid>
                </Grid>
            </BlockUi>
        );
    }
}

export default Watchers;