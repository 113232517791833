import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import EAMAutocomplete from 'eam-components/dist/ui/components/muiinputs/EAMAutocomplete';
import EAMInput from 'eam-components/dist/ui/components/muiinputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/muiinputs/EAMSelect';
import WS from '../../../../../tools/rest/WSFollowUps';
import { withStyles } from '@mui/styles';

const styles = {
    dialogPaper: {
        width: "100vh",
        height: "75vh",
        minHeight: 300,
        maxWidth: 400,
    }
}

export const DESCRIPTION = "description";
export const EQUIPMENT = "equipmentCode";
export const EQUIPMENT_DESC = "equipmentDesc";
export const LOCATION = "locationCode";
export const DEPARTMENT = "departmentCode";
export const DEPARTMENT_DESC = "departmentDesc";
export const STATUS = "statusCode";
export const TYPE = "typeCode";
export const REPORTED_BY = "reportedBy";
export const PRIORITY = "priorityCode";
export const PROBLEM_CODE = 'problemCode';
export const COST_CODE = 'costCode';
export const REQ_INTERV_PERIOD = 'udfchar17';
export const ASSIGNEDTO = 'assignedTo'

export const UDFS = 'userDefinedFields';

class CreateWODialog extends React.Component {

    sugestions = {}

    componentDidMount = () => {
        this.children = {};
    }

    validateFields = children => {
        let validationPassed = true
        Object.keys(children).forEach(key => {
            if (!children[key].validate()) {
                validationPassed = false
            }
        })
        return validationPassed
    }

    saveLink = () => {
        let validationPassed = this.validateFields(this.children);

        if (!validationPassed) {
            return;
        }

        this.props.save();
    }

    render() {
        const { cancel, open, updateProperty, classes, newWorkOrder, layout, problemCodes } = this.props;

        if (newWorkOrder === {}){
            return <div></div>
        }

        return (
            <Dialog
                open={open}
                classes={{paper: classes.dialogPaper}}
            >
                <DialogTitle>
                    Create Work Request
                </DialogTitle>
                <DialogContent>
                    <EAMInput
                        children={this.children}
                        elementInfo={{
                            attribute: 'R',
                            text: "Description",
                            xpath: DESCRIPTION
                        }}
                        updateProperty={updateProperty}
                        value={newWorkOrder[DESCRIPTION]}
                        valueKey={DESCRIPTION}
                    />
                    <EAMAutocomplete
                        children={this.children}
                        elementInfo={{
                            ...(layout.woLayout.fields["equipment"]),
                                    attribute: 'R'
                            }}
                        updateProperty={updateProperty}
                        value={newWorkOrder[EQUIPMENT]}
                        valueKey={EQUIPMENT}
                        valueDesc={newWorkOrder[EQUIPMENT_DESC]}
                        descKey={EQUIPMENT_DESC}
                        autocompleteHandler={(hint, config) => WS.autocompleteWO("equipment", hint, config)}
                    />
                    <EAMAutocomplete
                        children={this.children}
                        elementInfo={layout.woLayout.fields['location']}
                        updateProperty={updateProperty}
                        value={newWorkOrder[LOCATION]}
                        valueKey={LOCATION}
                        autocompleteHandler={(hint, config) => WS.autocompleteWO('location', hint, config)}
                    />
                    <EAMAutocomplete
                        children={this.children}
                        elementInfo={layout.woLayout.fields["department"]}
                        updateProperty={updateProperty}
                        value={newWorkOrder[DEPARTMENT]}
                        valueKey={DEPARTMENT}
                        autocompleteHandler={(hint, config) => WS.autocompleteWO("department", hint, config)}
                        descKey={DEPARTMENT_DESC}
                        valueDesc={newWorkOrder[DEPARTMENT_DESC]}
                    />
                    <EAMSelect
                        children={this.children}
                        elementInfo={layout.woLayout.fields[REQ_INTERV_PERIOD]}
                        updateProperty={updateProperty}
                        value={newWorkOrder[UDFS] ? newWorkOrder[UDFS][REQ_INTERV_PERIOD] : null}
                        valueKey={REQ_INTERV_PERIOD}
                        values={layout.woLayout.workOrderInterventionPeriods}
                    />
                    <EAMSelect
                        children={this.children}
                        elementInfo={{
                            attribute: 'R',
                            text: "WO Status",
                            xpath: STATUS
                        }}
                        updateProperty={updateProperty}
                        value={newWorkOrder[STATUS]}
                        valueKey={STATUS}
                        values={layout.woLayout.woStatuses}
                    />
                    <EAMSelect
                        children={this.children}
                        elementInfo={{
                            attribute: 'R',
                            text: "WO Type",
                            xpath: TYPE
                        }}
                        updateProperty={updateProperty}
                        value={newWorkOrder[TYPE]}
                        valueKey={TYPE}
                        values={layout.woLayout.woTypes}
                    />
                    <EAMAutocomplete
                        children={this.children}
                        elementInfo={{
                            attribute: 'R',
                            text: "Reported by",
                            xpath: REPORTED_BY
                        }}
                        updateProperty={updateProperty}
                        value={newWorkOrder[REPORTED_BY]}
                        valueKey={REPORTED_BY}
                        //valueDesc={newWorkOrder[REPORTED_BY + 'asd']}
                        //descKey={REPORTED_BY + 'asd'}
                        //loadOptions={this.autocompleteEmployee}
                        autocompleteHandler={(hint, config) => WS.autocompleteWO(REPORTED_BY, hint, config)}
                    />

                    {/* <EAMAutocomplete
                        children={this.children}
                        elementInfo={layout.woLayout.fields[ASSIGNEDTO.toLowerCase()]}
                        updateProperty={updateProperty}
                        value={newWorkOrder[ASSIGNEDTO]}
                        valueKey={ASSIGNEDTO}
                        autocompleteHandler={(hint, config) => WS.autocompleteWO(ASSIGNEDTO, hint, config)}
                        loadOptions={(hint, config) => WS.autocompleteWO(ASSIGNEDTO, hint, config)}
                    /> */}

                    <EAMSelect
                        children={this.children}
                        elementInfo={layout.woLayout.fields['priority']}
                        updateProperty={updateProperty}
                        value={newWorkOrder[PRIORITY]}
                        valueKey={PRIORITY}
                        values={layout.woLayout.woPriorities}
                    />
                    <EAMSelect
                        children={this.children}
                        elementInfo={{
                            attribute: "O",
                            text: "Problem code",
                            xpath: PROBLEM_CODE
                        }}
                        updateProperty={updateProperty}
                        value={newWorkOrder[PROBLEM_CODE]}
                        valueKey={PROBLEM_CODE}
                        values={problemCodes}
                    />
                    <EAMAutocomplete
                        children={this.children}
                        elementInfo={{
                            attribute: "O",
                            text: "Cost code",
                            xpath: COST_CODE
                        }}
                        updateProperty={updateProperty}
                        value={newWorkOrder[COST_CODE]}
                        valueKey={COST_CODE}
                        autocompleteHandler={(hint, config) => WS.autocompleteWO(COST_CODE, hint, config)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel} color="primary" >
                        Cancel
                    </Button>
                    <Button onClick={this.saveLink} color="primary" >
                        Create work request
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(CreateWODialog);