import React from 'react';
import Panel from 'ui/components/Panel';

class ConnectionsPanel extends React.Component {

    render() {
        return (
            <embed
                src={process.env.REACT_APP_FRONTEND+`connections/connections.html?appbackend=${process.env.REACT_APP_BACKEND}&appfrontend=${process.env.REACT_APP_FRONTEND}&code=${this.props.log?.code || this.props.logNumber}&screen=${this.props.logbookCode}`}
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    minHeight: 450
                }}
            />
        );
    }
}

export default ConnectionsPanel;