import WS from './WS'

class WSWatchers {

    autocompleteWatcher(hint, config = {}) {
        return WS._get(`/watchers/autocomplete?hint=${hint}`, config);
    }

    getWatchers(screenCode, caseCode, config = {}) {
        return WS._get(`/watchers/${screenCode}/${caseCode}`, config);
    }

    addWatcher = watcher => {
        return WS._post(`/watchers/`, watcher);
    }

    // delete is handled with a post
    // because we need to pass a payload
    deleteWatcher = watcher => {
        return WS._post(`/watchers/delete/`, watcher);
    }


}

export default new WSWatchers();