import { Button, Grid } from "@mui/material";
import { Delete } from "@mui/icons-material";
import EAMCheckbox from "eam-components/dist/ui/components/muiinputs/EAMCheckbox";
import React from "react";
import BlockUi from "react-block-ui";
import Panel from 'ui/components/Panel';
import EISTable from "react-eis-components/ui/components/table";
import WS from "../../../../../tools/rest/WSFollowUps";
import AddWODialog from "./AddWODialog";
import CreateWODialog, {
    ASSIGNEDTO,
    COST_CODE,
    DESCRIPTION,
    EQUIPMENT,
    REPORTED_BY,
    STATUS,
    UDFS,
} from "./CreateWODialog";
import CreateWORDialog from "ui/pages/LogMain/CreateWORDialog";
import { withStyles } from "@mui/styles";
import { LOG_DTO } from "enums/LogDTO";
import { SITE, SUBSYSTEM } from "ui/pages/LogMain/CRYOPositionCapture";

export const CI_INSTRUMENTATION = "checkbox02";

const styles = (theme) => ({
    root: {
        height: "100%",
        width: '100%',
        padding: 0,
        margin: 0,
        backgroundColor: "#eee",
        "-webkit-overflow-scrolling": "touch",
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
    },
    loading: {
        color: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    blockSection: {
        marginBottom: '6px',
        padding: '4px',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.main[100]
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    width: '100%',
                    boxSizing: 'border-box',
                    margin: 0,
                    '&.MuiGrid-item': {
                        boxSizing: 'border-box',
                        margin: '0px',
                        padding: '8px 0 0 8px',
                    },
                },
            },
        },
    }
});

class FollowUPWorkOrders extends React.Component {
    state = {
        followUps: [],
        newWorkOrder: {},
        addLinkDialogOpen: false,
        createWODialogOpen: false,
        dialogOpen: '',
    };

    componentDidMount = () => {
        this.loadFollowUps();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.log?.code !== this.props.log?.code || prevProps.logNumber !== this.props.logNumber) {
            this.loadFollowUps();
        }
    };

    loadFollowUps() {
        this.setState(() => ({
            loading: true,
        }));

        WS.getFollowUps(this.props.log?.code || this.props.logNumber)
            .then((response) => {
                this.setState(() => ({
                    followUps: response.body.data,
                    loading: false,
                }));
            })
            .catch((error) => {
                this.props.handleError(error);
                this.setState(() => ({
                    loading: false,
                }));
            });
    }

    updateProperty = (key, value) => {
        if (!key) {
            return;
        }

        if (key === EQUIPMENT) {
            this.fetchProblemCodes(value);
        }

        this.setState(() => ({
            [key]: value,
        }));
    };

    fetchProblemCodes = (equipment) => {
        WS.getProblemCodes(equipment)
            .then((response) => {
                this.setState(() => ({
                    problemCodes: response.body.data,
                }));

                return WS.getCostCode(equipment);
            })
            .then((result) => {
                let costCode = result.body.data;
                if (costCode && typeof costCode === "string")
                    this.updateNewWorkOrder(COST_CODE, costCode);
            });
    };

    updateNewWorkOrder = (key, value) => {
        if (!key) return;
        if (key === EQUIPMENT) this.fetchProblemCodes(value);

        this.setState((prevState) => {
            let more = key.startsWith("udf")
                ? {
                      [UDFS]: {
                          ...prevState[UDFS],
                          [key]: value,
                      },
                  }
                : { [key]: value };

            return {
                newWorkOrder: {
                    ...prevState.newWorkOrder,
                    ...more,
                },
            };
        });
    };

    addFollowUp = () => {
        this.setState(() => ({
            loading: true,
        }));
        if (this.state.woCodeToLink) {
            WS.addFollowUp(this.props.log?.code || this.props.logNumber, this.state.woCodeToLink)
                .then((response) => {
                    this.setState(() => ({
                        followUps: response.body.data,
                        loading: false,
                        addLinkDialogOpen: false,
                    }));
                })
                .catch((error) => {
                    this.props.handleError(error);
                    this.setState(() => ({
                        loading: false,
                        addLinkDialogOpen: false,
                    }));
                });
        }
    };

    deleteFollowUp = (woCode) => {
        this.setState(() => ({
            loading: true,
        }));

        WS.deleteFollowUp(this.props.log?.code || this.props.logNumber, woCode)
            .then((response) => {
                this.setState(() => ({
                    followUps: response.body.data,
                    loading: false,
                }));
            })
            .catch((error) => {
                this.props.handleError(error);
                this.setState(() => ({
                    loading: false,
                }));
            });
    };

    cancelAddLink = () => {
        this.setState(() => ({
            addLinkDialogOpen: false,
        }));
    };

    openAddLink = () => {
        this.setState(() => ({
            addLinkDialogOpen: true,
        }));
    };

    openCreateWO = () => {
        // assign default values to new WO
        this.setState(
            (prevState) => ({
                newWorkOrder: {
                    [DESCRIPTION]: this.props.log[DESCRIPTION],
                    [EQUIPMENT]: this.props.log[EQUIPMENT] ?? this.props.log.userDefinedFields[SUBSYSTEM] ??
                        this.props.log.userDefinedFields[SITE],
                    [REPORTED_BY]: this.props.userData.eamAccount.cernId,
                    [ASSIGNEDTO]: this.props.log.assignedToCode,
                    departmentCode: this.props.log.departmentCode,
                    [UDFS]: {},
                },
                createWODialogOpen: true,
            }),
            () => {
                if (this.state.newWorkOrder[EQUIPMENT]) {
                    this.fetchProblemCodes(this.state.newWorkOrder[EQUIPMENT]);
                }
            }
        );
    };

    openCreateWO2 = () => {
        // assign default values to new WO
        this.setState({
            newWorkOrder: {
                [DESCRIPTION]: this.props.log[LOG_DTO.DESCRIPTION],
                [EQUIPMENT]: this.props.log[EQUIPMENT] ?? this.props.log.userDefinedFields[SUBSYSTEM] ?? this.props.log.userDefinedFields[SITE],
                [REPORTED_BY]: this.props.userData.eamAccount.cernId,
                [ASSIGNEDTO]: this.props.log.assignedToCode,
            },
            dialogOpen: 'CREATE_WOR'
        });
    };


    cancelCreateWO = () => {
        this.setState(() => ({
            createWODialogOpen: false,
        }));
    };

    prepareDataForTable = () => {
        const { applicationData } = this.props;

        return this.state.followUps.map((elem) => ({
            ...elem,
            number: (
                <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`${applicationData.eamlightUrl}workorder/${elem.number}`}
                >
                    {elem.number}
                </a>
            ),
            delete: (
                <Delete
                    onClick={() => this.deleteFollowUp(elem.number)}
                    style={{ cursor: "pointer" }}
                />
            ),
        }));
    };

    createFollowUp = (workorder) => {
        this.setState(() => ({
            loading: true,
        }));

        WS.createFollowUp(
            this.props.logbookCode,
            this.props.log?.code || this.props.logNumber,
            workorder
        )
            .then((response) => {
                this.setState(() => ({
                    followUps: response.body.data,
                    loading: false,
                    createWODialogOpen: false,
                }));
            })
            .catch((error) => {
                this.props.handleError(error);
                this.setState(() => ({
                    loading: false,
                }));
            });
    };

    createFollowUpWOR = async (workorder) => {
        this.setState(() => ({
            loading: true,
        }));

        try {
            const followUps = await WS.createFollowUpWOR(this.props.log?.code || this.props.logNumber, workorder);
            this.setState({
                followUps,
                loading: false,
                dialogOpen: ''
            });
        } catch (error) {
            this.props.handleError(error);
            this.setState({ loading: false});
        }
    };

    render() {
        const {
            layout,
            extraFields,
            updateExtraField,
            logbookData,
            name,
            panels,
            onPanelChange,
            match,
            logbookCode,
            v2,
            defaultExpanded,
            readonly,
        } = this.props;

        return (
            <div style={{padding: '8px 16px 16px'}}>
                <BlockUi
                    tag="div"
                    blocking={this.state.loading}
                    style={{ height: "100%", width: "100%" }}
                >
                    <Grid container spacing={16}>
                        {readonly ? null : <Grid item lg={4} md={6} sm={12}>
                            <Button onClick={this.openAddLink}>
                                Link Work Order
                            </Button>
                        </Grid>}
                        {!v2  ?
                            <Grid item lg={4} md={6} sm={12}>
                                <Button onClick={this.openCreateWO}>
                                    Create Work Request
                                </Button>
                            </Grid>
                            : readonly ? null : <Grid item lg={4} md={6} sm={12}>
                                <Button onClick={() => this.openCreateWO2()}>
                                    Create Work O/R V2
                                </Button>
                            </Grid>
                        }
                        {this.state.followUps.length > 0 && (
                            <Grid style={{padding: 0, margin: 0}}  item xs={12} xl={12}>
                                <EISTable
                                    data={this.prepareDataForTable()}
                                    headers={[
                                        "Number",
                                        "Description",
                                        "Status",
                                        "Delete",
                                    ]}
                                    propCodes={[
                                        "number",
                                        "desc",
                                        "status",
                                        "delete",
                                    ]}
                                />
                            </Grid>
                        )}
                    </Grid>
                </BlockUi>
                <AddWODialog
                    cancel={this.cancelAddLink}
                    save={this.addFollowUp}
                    open={this.state.addLinkDialogOpen}
                    updateProperty={this.updateProperty}
                    woCodeToLink={this.state.woCodeToLink}
                />
                {!v2 ?
                    readonly ? null : <CreateWODialog
                        cancel={this.cancelCreateWO}
                        save={() => this.createFollowUp(this.state.newWorkOrder)}
                        updateProperty={this.updateNewWorkOrder}
                        open={this.state.createWODialogOpen}
                        newWorkOrder={this.state.newWorkOrder}
                        problemCodes={this.state.problemCodes}
                        layout={layout}
                        log={this.props.log}
                    />
                    : this.state.dialogOpen === 'CREATE_WOR' && <CreateWORDialog
                        cancel={() => this.setState({ dialogOpen: ''})}
                        open={this.state.dialogOpen === 'CREATE_WOR'}
                        save={this.createFollowUpWOR}
                        updateProperty={this.updateNewWorkOrder}
                        newWorkOrder={this.state.newWorkOrder}
                        problemCodes={this.state.problemCodes}
                        layout={layout}
                        log={this.props.log}
                        match={match}
                        logbookCode={logbookCode}
                        baseWo={this.state.newWorkOrder}
                    />
                }
            </div>
        );
    }
}

export default withStyles(styles)(FollowUPWorkOrders);
