// @flow
import client from "./client";

class WS {
    getUserData(config = {}) {
        return this._get("/application/userdata", config);
    }

    getApplicationData(config = {}) {
        return this._get("/application/applicationdata", config);
    }

    getLayout(screenCode, config = {}) {
        return this._get(`/application/layout/${screenCode}`, config);
    }

    getLogbookDataspies(gridId, config = {}) {
        return this._get(`/grids/${gridId}/metadata?lang=EN`, config);
    }

    getCaseHistory = (caseCode) => {
        return this._get(`/history/${caseCode}`);
    };

    getLogbookConfiguration = async (config = {}) => {
        return (await this._get('/logbooks/configuration', config))?.body.data;
    }

    _get(url, config = {}) {
        return client.get(process.env.REACT_APP_BACKEND + url, config);
    }

    _post(url, data, config = {}) {
        return client.post(process.env.REACT_APP_BACKEND + url, data, config);
    }

    _put(url, data, config = {}) {
        return client.put(process.env.REACT_APP_BACKEND + url, data, config);
    }

    _delete(url, config = {}) {
        return client.delete(process.env.REACT_APP_BACKEND + url, config);
    }

    __get = async (url, config = {}) => (await client.get(process.env.REACT_APP_BACKEND + url, config))?.body.data;

    __post = async (url, data, config = {}) => (await client.post(process.env.REACT_APP_BACKEND + url, data, config))?.body.data

    __put = async (url, data, config = {}) => (await client.put(process.env.REACT_APP_BACKEND + url, data, config))?.body.data

    __delete = async (url, config = {}) => (await client.delete(process.env.REACT_APP_BACKEND + url, config))?.body.data
}

export default new WS();
