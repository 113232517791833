import React from 'react';
import { withStyles } from '@mui/styles';

const styles = () => ({
    root: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
});

const NotFoundPage = (props) => {
    const { classes } = props;
    return (
        <section className={classes.root}>
            <p>
                <b>404 Page Not Found</b>
            </p>
            <p>The page you are trying to access does not exist</p>
        </section>
    );
};

export default withStyles(styles)(NotFoundPage);
