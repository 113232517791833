import { alpha, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EAMGrid from "eam-components/dist/ui/components/grids/eam/EAMGrid";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { handleError } from 'react-eis-components/actions/uiActions';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { ROUTES } from 'Routes';
import { COLORS } from 'tools/COLORS';
import SyncedQueryParamsEAMGridContext from 'ui/components/SyncedQueryParamsEAMGridContext';
import { CustomIcon } from '../LogbookSelection/LogbookLinks';
import { compare } from './ScreenEAMBlock';
import queryString from 'query-string';

const EXTRA_COLUMNS = {
    OPEN_IN_NEW_COLUMN:'openInNewTab',
    ICON: 'icon',
};

const replaceAllOccurrences = (str, obj) => str?.replace(/{(.*?)}/g, (match, field) => obj?.[field] ?? match);

const useStyles = makeStyles((theme) => ({
    rowStyler: {
        cursor: "pointer",
        minHeight: "44px",
        maxHeight: '132px',
        '&:hover': {
            boxShadow: `inset 0px 0px 0px 3px ${theme.palette.primary.main}, inset 0 0 0 999px ${alpha(theme.palette.primary.main, 0.15)}`
        }
    },
    icon: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const cellRenderer = ({ applyStyles, classes, logbookConf, customLink }) => ({ column, value, row }) => {
    if (column.id === EXTRA_COLUMNS.OPEN_IN_NEW_COLUMN) {
        return <Icon
            variant="contained"
            color="primary"
            style={{
                textAlign: 'center',
            }}
            onClick={
                evt => {
                    window.open(getLogLink({ logbookConf, row: row.values, customLink }),  "_blank")
                    evt.stopPropagation();
                }
            }
        >
            open_in_new
        </Icon>
    }

    if (column.id === EXTRA_COLUMNS.ICON) {
        const styleApply = applyStyles(row.values);
        return <div className={classes.icon}><CustomIcon icon={styleApply?.icon?.attributeValue}/></div>
    }

    if (!value) {
        return "";
    }
};

const applyStylesFunction = (orderedStyles) => (values) => {
    const styleApply = orderedStyles
        .map(style =>
            style.find(conditionList =>
                conditionList.every(cond => compare(cond?.operator, values[cond.tagName?.toLowerCase()], cond.tagValue))
            )?.[0]
        )
        .filter(s => s)
        .reduce((acc, el) => ({...acc, [el.attributeName]: el}) ,{});

    return styleApply;
}

const getLogLink = ({ logbookConf, row, customLink }) => customLink ?
    replaceAllOccurrences(customLink, row)
    : generatePath(ROUTES.LOGBOOK_EDITLOGV2, {logbookCode: logbookConf?.logbook, logNumber: row.casecode});

const onRowClick = ({ logbookConf, history, customLink }) => (row) =>
    (customLink ? window.open : history.push)(getLogLink({ logbookConf, row, customLink }))

const rowStyler = ({ logbookConf, classes, applyStylesFunction, history, customLink }) => ({ values }) => {
    const styleApply = applyStylesFunction(values);
    return {
        style: COLORS[styleApply?.color?.attributeValue],
        className: classes.rowStyler,
        onClick: () => onRowClick({logbookConf, history, customLink})(values)
    }
};

const LogGrid = ({ match, history, logbookDashboard, hideFooter, hideHeader, gridName, dataspyId, filters = [], customLink }) =>  {
    const dispatch = useDispatch();
    const { logbooks, skipTranslations } = useSelector(state => state.application);
    const { logbookCode = logbookDashboard, lang } = match?.params || {};

    const classes = useStyles();

    const [orderedStyles, setOrderedStyles] = useState([]);
    const [extraColumns, setExtraColumns] = useState([]);

    useEffect(() => {
        const stylesByAttr = logbookConf?.logbookStyles?.reduce((acc, el) => ({...acc, [el.attributeName]: [...(acc[el.attributeName] || []), el]}), {}) || {}
        const orderedStyles = Object.values(stylesByAttr)
            .map(
                styl => Object.values(
                    styl?.reduce((acc, el) => ({...acc, [el.sequence]: [...(acc[el.sequence] || []), el]}), {}) || {}
                )
                .sort((a, b) => a[0].sequence - b[0].sequence)
            );
        setOrderedStyles(orderedStyles);

        const extraColumnsTemp = [
            ...(logbookConf?.openLogSameTab ?
                [{
                    width: '40px',
                    headerLabel: "",
                    t: EXTRA_COLUMNS.OPEN_IN_NEW_COLUMN,
                }]
                : []),
            ...(stylesByAttr?.icon ?
                [{
                    width: '50px',
                    label: "Icon",
                    t: EXTRA_COLUMNS.ICON,
                }]
                : []),

        ]
        setExtraColumns(extraColumnsTemp);
    }, [logbookCode])

    const logbookConf = logbooks.find(conf => conf.logbook === logbookCode);

    const applyStyles = useCallback(applyStylesFunction(orderedStyles), [orderedStyles]);
    const rowStylerF = useCallback(
        (row) => rowStyler(
            {
                history,
                classes,
                logbookConf,
                applyStylesFunction: applyStyles,
                customLink,
            })?.(row, logbookConf?.logbookStyles)
        , [applyStyles]);

    const grdName = gridName ?? logbookConf?.gridName ?? logbookConf?.cloneScreen;
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (grdName) {
            setRefresh(true);
        }
    }, [grdName])


    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);


    if (refresh) return null;

    return <SyncedQueryParamsEAMGridContext
                searchOnMount
                gridName={grdName}
                modifyEAMGridColumns={(s) => [...(extraColumns?.map(({ width, t, label }) => ({ name: t, label: label ?? "", width: width?.replaceAll('px', '')})) ?? []), ...s.filter(col => col.visible)]}
                useNative={true}
                cellRenderer={cellRenderer({applyStyles, classes, logbookConf})}
                initialShowFilters={false}
                gridRequestAdapter={(request) => ({...request,
                    gridFilter: [
                        ...request.gridFilter || [],
                        ...filters,
                        { fieldName: 'udfchar05', fieldValue: logbookCode, joiner: 'AND', operator: 'EQUALS' },
                        { fieldName: 'csm_udfchar05', fieldValue: logbookCode, joiner: 'AND', operator: 'EQUALS' },

                    ]})}
                translateHeaders={!skipTranslations}
                {...dataspyId ? {initialDataspyID: dataspyId} : {}}
            >
            <EAMGrid
                getRowProps={rowStylerF}
                handleError={(...props) => dispatch(handleError(...props))}
                hideFooter={hideFooter}
                hideHeader={hideHeader}
            />
        </SyncedQueryParamsEAMGridContext>
}

export default LogGrid;
