import ErrorTypes from "../enums/ErrorTypes";
import queryString from 'query-string';

/**
 * General tools for the app
 */
class Tools {

    /**
     * To get the value of a parameter from the URL
     * @param name (Key) of the parameter
     * @returns {string} The value of the parameter,or an empty string
     */
    getURLParameterByName(name) {
        const url = window.location.href;
        name = name.replace(/[[]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results || !results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    getURLParams = () => queryString.parse(window.location.search, { arrayFormat: 'comma' })

    /**
     * To get the custom fields list with code desc
     * @param customField The custom field
     */
    getCustomFieldsCodeDesc(customField) {
        let values = [];
        for (let i = 0; i < customField.pcode.length; i++) {
            if (customField.pcode[i])/*To remove empty value*/
                values.push({code: customField.pcode[i], desc: customField.pdesc[i]});
        }
        return values;
    }

    /**
     * Get the description of a custom field value code
     * @param customField The definition of the custom field
     * @param valueCode the code to be searched
     * @returns {*} Description of the selected code
     */
    getCustomFieldValueDesc(customField, valueCode) {
        for (let i = 0; i < customField.pcode.length; i++) {
            if (customField.pcode[i] === valueCode) {
                return customField.pdesc[i];
            }
        }
        return '';
    }

    /**
     * Filters the list of custom fields to keep only the list of values received
     * @param customField The custom field to be filtered
     * @param valuesToKeep The list of values to be kept
     */
    filterCustomFieldValues(customField, valuesToKeep) {
        let pcode = [];
        let pdesc = [];
        for (let i = 0; i < customField.pcode.length; i++) {
            const code = customField.pcode[i];
            if (valuesToKeep.includes(code)) {
                pcode.push(code);
                pdesc.push(customField.pdesc[i]);
            }
        }
        return {...customField, pcode, pdesc};
    }

    /**
     * To create a server error that will be displayed on the screen
     * @param title Title of the error
     * @param detail Detail
     * @returns {{type: string, response: {status: number, body: {errors: *[]}}}}
     */
    createServerError(title, detail) {
        return {
            type: ErrorTypes.SERVER_ERROR,
            response: {
                status: 400,
                body: {
                    errors: [{
                        title: title,
                        detail: detail
                    }]
                }
            }
        };
    }

    /**
     * To get the value of a cell  in a grid row
     * @param row The grid row to get the cell value
     * @param cellTag The identifier for the cell
     */
    getGridCellValueFromRow(row, cellTag) {
        const cell = row.cell.find(elem => elem.t === cellTag);
        return cell ? cell.value : '';
    }

    /**
     * To set the value of a cell  in a grid row
     * @param row The grid row to set the cell value to
     * @param cellTag The identifier for the cell
     */
    setGridCellValueFromRow(row, cellTag, value) {
        const cell = row.cell.find(elem => elem.t === cellTag);
        cell.value = value;
    }

    toDateString(date) {
        return date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + date.getDate();
    }

    /**
     * Create the default confirm object
     * @param translations Translations
     * @returns {{title: *, message: *, cancelButtonLabel: *, confirmButtonLabel: *, waitForCompletion: boolean}}
     */
    createDefaultConfirmObj(translations) {
        return {
            title: translations.CONFIRM,
            message: translations.AREYOUSURE,
            cancelButtonLabel: translations.CANCEL,
            confirmButtonLabel: translations.CONFIRM,
            waitForCompletion: true
        }
    }

    userCanModifyLog(log, eamAccount) {
        return ["R5", "QCERN+"].indexOf(eamAccount.userGroup) !== -1 || log?.createdBy === eamAccount.userCode;
    }

}

export default new Tools();