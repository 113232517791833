import { Add, Menu, MenuBook } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { version } from '../package.json';
import Footer from 'eam-components/dist/ui/components/footer/Footer';
import 'Logbook.css';
import LogbookAppBarContainer from 'LogbookAppBarContainer';
import { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import EISLayout from 'react-eis-components/ui/layout/EISLayout';
import { BrowserRouter as Router, generatePath, Link, Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from 'Routes';
import InfoPage from 'ui/components/infopage/InfoPage';
import NotFoundPage from "ui/components/notfound/NotFoundPage";
import AdminPageContainer from "ui/pages/Admin/AdminPageContainer";
import HistoryContainer from "ui/pages/History/HistoryContainer";
import LogbookPageContainer from "ui/pages/LogbookPageContainer";
import LogbookSelectionContainer from "ui/pages/LogbookSelection/LogbookSelectionContainer";
import LogDetailsPageContainer from "ui/pages/LogDetails/LogDetailsPageContainer";
import LogMain from 'ui/pages/LogMain';
import LogPageV2 from 'ui/pages/LogMain/LogPageV2';
import LogSelectionContainer from "ui/pages/LogSelection/LogSelectionContainer";
import ReleaseNotesPage from 'ui/pages/ReleaseNotes/ReleaseNotesPage';

const styles = (theme) => ({
    '@media (max-width: 400px)': {
        hideOnMobile: {
            display: "none"
        },
        appDivName: {
            width: 100
        }
    },
    '@media (min-width: 400px)': {
        appDivName: {
            width: 220
        }
    },
    content: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.default,
        // padding: theme.spacing.unit * 3,
    },
    logoHeader: {
        display: "flex",
        flexDirection: "column",
    },
    logoTitle: {
        fontSize: 18,
        fontWeight: "bold",
    },
    logoEnv: {
        fontSize: 12,
    }
});

class Logbook extends Component {

    blockUiStyle = {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    blockUiStyleDiv = {
        display: "flex",
        height: 60,
        alignItems: "flex-end"
    };

    state = {
        open: false,
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    componentWillReceiveProps(newProp) {
        const storeinfo = newProp.storeData ? newProp.storeData.filter(s => s.code === newProp.storeCode)[0] : undefined;
        if (storeinfo) {
            this.props.updateApplication({currentStore: {code: storeinfo.code, desc: storeinfo.desc}});
        }
    }

    render() {
        const { applicationData, classes, userData, logbookCode, showError, showSuccess, logbookDesc, configuration } = this.props;

        // User data still not there, display loading page
        if (!userData) {
            return (
                <BlockUi tag="div" blocking={true} style={this.blockUiStyle}>
                    <div style={this.blockUiStyleDiv}>Loading Logbook ...</div>
                </BlockUi>
            )
        }

        // Error when initializing application
        if (userData.invalidAccount) {
            return <InfoPage title="Error"
                             message="Application couldn't be initialized. Plase contact CMMS Support"/>
        }

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={this.props.theme}>
                    <Router basename={process.env.PUBLIC_URL}>
                        <EISLayout
                            userDesc={userData.eamAccount.userDesc}
                            userCode={userData.eamAccount.userCode}
                            appName={() =>
                                <div style={{marginLeft: 10, display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <div className={classes.appDivName} style={{justifyContent:'space-between', color: '#FFF', fontWeight: "bold", fontSize: 18, display:'flex', alignItems: 'center'}}>

                                        <Link to={ROUTES.HOME} style={{color: '#FFF', fontSize: 18, display:'flex', alignItems: 'center', textDecoration: 'none'}}>
                                            <MenuBook style={{marginRight: '10px'}} />
                                            <section className={classes.logoHeader}>
                                                <div style={{justifyContent:'space-between', color: '#FFF', fontWeight: "bold", fontSize: 18, display:'flex', alignItems: 'center'}}>
                                                    <div>EAM Logbook</div>
                                                </div>
                                            </section>
                                        </Link>

                                        <IconButton style={{color:'white'}} onClick={this.handleDrawerOpen}>
                                            <Menu />
                                        </IconButton>
                                    </div>

                                    {logbookCode && <Link to={generatePath(ROUTES.LOGBOOK_CREATELOGV2, { logbookCode})} style={{color: '#FFF', fontSize: 18, display:'flex', alignItems: 'center', textDecoration: 'none'}}>
                                        <div style={{marginLeft:40, justifyContent:'space-between', color: '#FFF', fontWeight: "bold", fontSize: 16, display:'flex', alignItems: 'center'}} className={classes.hideOnMobile}>
                                        + New log
                                        </div>
                                    </Link>}

                                    {logbookDesc &&
                                        <Link to={generatePath(ROUTES.LOGBOOK_MAIN, { logbookCode})} style={{color: '#FFF', fontSize: 18, display:'flex', alignItems: 'center', textDecoration: 'none'}}>
                                            <div style={{marginLeft:40, justifyContent:'space-between', color: '#FFF', fontWeight: "", fontSize: 16, display:'flex', alignItems: 'center'}} className={classes.hideOnMobile}>
                                                {logbookDesc}
                                            </div>
                                        </Link>
                                    }
                                </div>
                            }
                            userInstitution="CERN"
                            signOutFromApplication={() => (new Promise(() => this.props.logout()))}
                            signOutFromCERN={() => {
                            }}
                            appBarContent={<LogbookAppBarContainer/>}
                            showCERNToolbar={false}
                            footerContent={
                                <Footer
                                    appName={"EAM Logbook " + (applicationData.environment !== 'PROD' ? applicationData.environment : "")}
                                    version={version}
                                    supportEmail={configuration?.find(c => c.logbook === 'GENERIC')?.supportEmail ?? ""}
                                    releaseNotesPath={ROUTES.RELEASE_NOTES}
                                />
                            }
                        >
                            <div onClick={this.handleDrawerClose} style={{height:'100%'}}>
                                <Switch>
                                    <Route
                                        exact
                                        path={ROUTES.ADMIN}
                                        component={AdminPageContainer}
                                    />
                                    <Route
                                        path={ROUTES.LOGBOOK_BASE + '?'}
                                        component={(props) => <LogbookPageContainer {...props} handleDrawerClose={this.handleDrawerClose.bind(this)} open={this.state.open} />}
                                    />
                                </Switch>
                                <Switch>
                                    <Route
                                        exact
                                        path={ROUTES.HOME}
                                        component={LogbookSelectionContainer}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.LOGBOOK_GRID}
                                        component={LogSelectionContainer}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.LOGBOOK_MAIN}
                                        component={LogMain}
                                    />
                                    {/* <Route
                                        exact
                                        path={ROUTES.LOGBOOK_LOG}
                                        component={LogDetailsPageContainer}
                                    /> */}
                                     <Route
                                        path={ROUTES.LOGBOOK_LOG}
                                        render={({ match, location }) => {
                                            const { screenCode, logNumber } = match.params;
                                            const path = logNumber ? generatePath(ROUTES.LOGBOOK_EDITLOGV2, {logbookCode: screenCode, })
                                                : generatePath(ROUTES.LOGBOOK_CREATELOGV2, {logbookCode: screenCode, logNumber});
                                            return <Redirect to={path + location.search} />
                                        }}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.LOGBOOK_CREATELOGV2}
                                        render={(props) => <LogPageV2 {...props} mode='CREATE'/>}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.LOGBOOK_CLONELOGV2}
                                        render={(props) => <LogPageV2 {...props} mode='CLONE'/>}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.LOGBOOK_EDITLOGV2}
                                        component={LogPageV2}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.LOGBOOK_LOGHISTORY}
                                        component={HistoryContainer}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.RELEASE_NOTES}
                                        component={ReleaseNotesPage}
                                    />
                                    <Route
                                        path={ROUTES.LOGBOOKV2_BASE}
                                        render={({ match }) => {
                                            console.warn('Redirecting to ' + generatePath(ROUTES.LOGBOOK_MAIN, match.params));
                                            return <Redirect to={generatePath(ROUTES.LOGBOOK_MAIN, match.params)} />
                                        }}
                                    />
                                    <Route
                                        path="*"
                                        component={NotFoundPage}
                                    />
                                </Switch>
                            </div>
                        </EISLayout>
                    </Router>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

export default withStyles(styles)(Logbook);
