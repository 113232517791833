import {connect} from 'react-redux'
import {updateLayout} from './actions/layoutActions'
import LogbookAppBar from "./LogbookAppBar";

function mapStateToProps(state) {
    return {
        userData: state.application.userData,
        storeCode: state.application.logBookCode
    }
}

const LogbookAppBarContainer = connect(mapStateToProps, {
    updateLayout
})(LogbookAppBar);

export default LogbookAppBarContainer