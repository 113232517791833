import { withStyles } from '@mui/styles';
// import { Account, Home, Logout } from 'mdi-material-ui';
import { Home, Logout, Person } from '@mui/icons-material';
import React from 'react';
import { logout } from "./AuthWrapper";

const styles = {
    '@media (max-width: 560px)': {
        storeDesc: {
            display: "none"
        },
        storeCode: {
            fontSize: 12
        }
    },
    '@media (min-width: 561px)' : {
        storeCode: {
            fontSize: 18
        }
    }
};

class LogbookAppBar extends React.Component {

    appBarStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    };

    userInfoStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end'
    };

    logoutIcon = {
        color: "rgba(255, 255, 255, 0.8)",
        height: 22,
        paddingLeft: 9,
        paddingRight: 9,
        borderLeft: "1px solid rgba(255, 255, 255, 0.8)",
        marginLeft: 14,
        fontSize: 18,
        lineHeight: '22px',
        cursor: 'pointer'
    };

    storeInfoStyle = {
        margin: 5,
        display: "flex",
        flexDirection: "column"
    };

    render() {
        return (
            <div style={this.appBarStyle}>
                {this.props.storeCode && (
                    <>
                        <Home style={{fontSize: 25}}/>
                        <div style={this.storeInfoStyle}>
                            <div style={{fontWeight: "bold"}} className={this.props.classes.storeCode}>{this.props.storeCode}</div>
                            <div style={{fontSize: 12}}
                                 className={this.props.classes.storeDesc}>{this.props.storeDesc}</div>
                        </div>
                    </>
                )}
                <div style={this.userInfoStyle}>
                    {this.props.userData.eamAccount.userCode && (
                        <>
                            <Person style={{fontSize: 20, margin: 5}}/>
                            {this.props.userData.eamAccount.userCode}
                            <div style={this.logoutIcon} onClick={logout}>
                                <Logout style={{fontSize: 20}}/>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(LogbookAppBar);