export const COLORS = {
    red: {
        color: '#bf360C',
        backgroundColor: 'rgba(251,233,231,0.7)'
    },
    green: {
        color: '#00701a !important',
        backgroundColor: 'rgba(118,210,117,0.1)'
    },
    orange: {
        color: "#FF7F2B",
        backgroundColor: "rgba(255,183,42,0.13)"
    },
    blue: {
        color: '#1976d2',
        backgroundColor: 'rgba(227,242,253,0.5)'
    },
    yellow: {
        color: '#330a00',
        backgroundColor: 'rgba(255, 255, 204, 0.85)'
    },
    pink: {
        color: '#8f3319',
        backgroundColor: 'rgba(252, 204, 239, 0.5)'
    },
}