import { withStyles } from '@mui/styles';
import React from 'react';
import LogbookButton from 'ui/components/buttons/LogbookButton';
import { ROUTES } from 'Routes';
import { generatePath } from 'react-router';
import { alpha, Button, Typography, CardActionArea, CardActions, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import LogbookLinks from './LogbookLinks';
import LogGrid from '../LogMain';
import ListPanel from './ListPanel';

const styles = (theme) => ({
    logbookCode: {
        whiteSpace: 'nowrap',
        fontSize: '16px',
        lineHeight: '1rem',
        paddingTop: '4px',
    },
    logbookDesc: {
        fontSize: '1.5rem',
        textShadow: `-1px -1px 0 ${theme.palette.secondary.main}, 1px -1px 0 ${theme.palette.secondary.main}, -1px 1px 0 ${theme.palette.secondary.main}, 1px 1px 0 ${theme.palette.secondary.main}`,
        lineHeight: '1.5rem',
        height: '3rem',
        textAlign: 'center',
    },
    logbookArea: {
        backgroundColor: alpha(theme.palette.secondary.main, 0.15),
        margin: '8px',
    },
    iconStyle: {
        color: theme.palette.primary.main
    }
});


class LogbookSelection extends React.Component {

    state = {};

    selectLogbook = (screenCode) => {
        this.props.setLogbook(screenCode);
        this.props.history.push(screenCode + "/logs");
    };

    render() {
        const { classes, logbooks, history } = this.props;
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%", overflow: 'auto', backgroundColor: "#f5f5fa" }}>
                <Grid container>
                    {logbooks.map((logbookConf) => <GetOptions history={history} logbookConf={logbookConf} classes={classes} />)}
                </Grid>
            </div>
        );
    }
}


const GetOptions = ({ history, logbookConf, classes }) => {
    return (
        <React.Fragment>
        <Grid xs={12} md={6} >
            <Paper elevation={0} sx={{ margin: 1 }}>
                <Card variant="outlined" >
                    <CardActionArea onClick={() => history.push(generatePath(ROUTES.LOGBOOK_MAIN, { logbookCode: logbookConf.logbook }))}>

                        <CardHeader
                            title={logbookConf.description}
                            subheader={logbookConf.logbook}
                        />

                        {logbookConf.cfg.image &&
                            <CardMedia component="img" height="194" image={logbookConf.cfg.image} alt={logbookConf.logbook} />
                        }

                    </CardActionArea>

                    <LogbookLinks logbookConf={logbookConf} history={history} />

                </Card>
            </Paper>
        </Grid>

        {logbookConf.cfg.panels?.map(panel => <ListPanel data={panel} logbookCode={logbookConf.logbook} history={history}/>)}

        </React.Fragment>

    )
}

export default withStyles(styles)(LogbookSelection);